import awsconfig from './aws-exports'
import { PathNames, ModalPathNames, PathNameRoutes } from './constants/pathNames'
/*Navigation*/
import { Nav } from './navigation/nav'
import { ProtectedRoute } from './managers/redirectManager/protectedRoute'
import { ConfirmedRoute } from './managers/redirectManager/confirmedRoute'
/*Components*/
import { BackgroundManager } from './managers/backgroundManager/backgroundManager'
import { ThemeManager } from './managers/themeManager/themeManager'
import { PostImagePreviewModal } from './components/feed/modal/postImagePreviewModal'
import { OpenImageModal } from './components/miscModals/openImageModal'
import { CreatePostRouteModal } from './components/feed/modal/createPostRouteModal'
import { ImageEditorRouteModal } from './components/profile/editor/editorModals/imageEditorRouteModal'
import { VideoEditorRouteModal } from './components/profile/editor/editorModals/videoEditorRouteModal'
import { NavMobileProfileMenu } from './navigation/navMobileProfileMenu'
import { SetStatusRouteModal } from './navigation/setStatusRouteModal'
/*Pages*/
import { Authentication } from './pages/authentication'
import { Bookmarks } from './pages/bookmarks'
import { Browse } from './pages/browse'
import { DevTools } from './pages/devTools'
import { Explore } from './pages/explore'
import { Hashtag } from './pages/hashtag'
import { Home } from './pages/home'
import { Notifications } from './pages/notifications'
import { Post } from './pages/post'
import { Profile } from './pages/profile'
import { ProfileSettings } from './pages/profileSettings'
import { RoadMap } from './pages/roadMap'
import { TermsOfService } from './pages/termsOfService'
import { PrivacyPolicy } from './pages/privacyPolicy'
import { Search } from './pages/search'
import { FollowList } from './pages/followList'
/*Cardinal*/
import { Cardinal, CardinalStateContext } from './context/cardinal'
import { Editor } from './context/editorContext'
/*Styles*/
import './globalstyles/App.css'
import './globalstyles/Crop.css'
import './globalstyles/Themes.css'
import { Messages } from './pages/messages'

import {
  Outlet,
  Routes,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  matchPath,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CardinalApolloProvider } from './apollo/cardinalApolloProvider'
import { MiniProfile } from './components/profile/display/miniProfile'
import { AuthProvider } from './context/authContext'
import { useContext, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { browserLocalPersistence, getAuth } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyB93gQ2aNLlTyG17aefkQ5TTiPURGy5EfM',
  authDomain: 'hasura-project113.firebaseapp.com',
  projectId: 'hasura-project113',
  storageBucket: 'hasura-project113.appspot.com',
  messagingSenderId: '647980309590',
  appId: '1:647980309590:web:58c7894a4af32086f4aff7',
}

function App() {
  const isDev = process.env.NODE_ENV === 'development' ? true : false

  // Initilize firebase.
  const firebaseApp = initializeApp(firebaseConfig)
  getAuth(firebaseApp).setPersistence(browserLocalPersistence)

  if (isDev) {
    const functions = getFunctions()
    connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  }

  function RootRouteComponent() {
    const location = useLocation()
    const background = location.state && location.state.background
    const { isMobileView } = useContext(CardinalStateContext)

    useEffect(() => {
      if (background) {
        document.documentElement.style.overflowY = 'hidden'
        if (isMobileView) document.documentElement.style.position = 'fixed'
      } else {
        document.documentElement.style.overflowY = 'scroll'
        //wonder if i should just make it unset so scroll thumb doesnt always show
        document.documentElement.style.position = 'unset'
      }
    }, [background])

    return (
      <>
        {/* <ScrollRestoration
          getKey={location => {
            const paths = ['/']
            return paths.includes(location.pathname) ? location.pathname : location.key
          }}
        /> */}
        <MiniProfile />
        <Nav />
        <BackgroundManager />

        <div className="App-Browser">
          <Routes location={background || location}>
            <Route index element={<ConfirmedRoute component={<Home />} />} />
            <Route path={PathNames.TOS} element={<ConfirmedRoute component={<TermsOfService />} />} />
            <Route path={PathNames.Privacy} element={<ConfirmedRoute component={<PrivacyPolicy />} />} />
            <Route path={PathNames.Profile} element={<ConfirmedRoute component={<Profile />} />} />
            <Route path={PathNames.ProfileTab} element={<ConfirmedRoute component={<Profile />} />} />
            <Route path={PathNames.Followers} element={<ConfirmedRoute component={<FollowList />} />} />
            <Route path={PathNames.Following} element={<ConfirmedRoute component={<FollowList />} />} />
            <Route path={PathNames.Followers_You_Follow} element={<ConfirmedRoute component={<FollowList />} />} />
            <Route
              path={PathNames.DevTools}
              element={isDev ? <ConfirmedRoute component={<DevTools />} /> : <ConfirmedRoute component={<Home />} />}
            />
            <Route path={PathNames.Post} element={<ConfirmedRoute component={<Post />} />} />
            <Route path={PathNames.RoadMap} element={<ConfirmedRoute component={<RoadMap />} />} />
            <Route path={PathNames.Explore} element={<ConfirmedRoute component={<Explore />} />} />
            <Route path={PathNames.Bookmarks} element={<ConfirmedRoute component={<Bookmarks />} />} />
            <Route path={PathNames.Search} element={<ConfirmedRoute component={<Search />} />} />
            <Route path={PathNames.SearchId} element={<ConfirmedRoute component={<Search />} />} />
            <Route path={PathNames.Hashtag} element={<ConfirmedRoute component={<Hashtag />} />} />
            <Route path={PathNames.Browse} element={<ConfirmedRoute component={<Browse />} />} />
            <Route path={PathNames.Authentication} element={<ProtectedRoute component={<Authentication />} />} />
            <Route path={PathNames.Messages} element={<ProtectedRoute component={<Messages />} />} />
            <Route path={PathNames.MessagesId} element={<ProtectedRoute component={<Messages />} />} />
            <Route path={PathNames.Settings} element={<ProtectedRoute component={<ProfileSettings />} />} />
            <Route path={PathNames.SettingsTab} element={<ProtectedRoute component={<ProfileSettings />} />} />
            <Route path={PathNames.Notifications} element={<ProtectedRoute component={<Notifications />} />} />
          </Routes>

          <Routes location={location}>
            <Route path={ModalPathNames.PostImage} element={<ConfirmedRoute component={<PostImagePreviewModal />} />} />
            <Route path={ModalPathNames.SetStatus} element={<ConfirmedRoute component={<SetStatusRouteModal />} />} />
            <Route path={ModalPathNames.OpenImage} element={<ConfirmedRoute component={<OpenImageModal />} />} />
            <Route
              path={ModalPathNames.ShowcaseImageEditor}
              element={<ConfirmedRoute component={<ImageEditorRouteModal />} />}
            />
            <Route
              path={ModalPathNames.ShowcaseVideoEditor}
              element={<ConfirmedRoute component={<VideoEditorRouteModal />} />}
            />
            <Route
              path={ModalPathNames.MobileProfileNav}
              element={<ConfirmedRoute component={<NavMobileProfileMenu />} />}
            />
            <Route path={ModalPathNames.CreatePost} element={<ProtectedRoute component={<CreatePostRouteModal />} />} />
          </Routes>

          <Outlet />
        </div>
      </>
    )
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootRouteComponent />}>
        {PathNameRoutes.map((item, index) => (
          <Route key={index} path={item} element={null} />
        ))}
      </Route>
    )
  )

  return (
    <AuthProvider>
      <CardinalApolloProvider>
        <Cardinal>
          <Editor>
            <ThemeManager>
              <div className="App">
                <ToastContainer theme="dark" position="bottom-left" limit={5} closeOnClick={false} />
                <RouterProvider router={router} />
              </div>
            </ThemeManager>
          </Editor>
        </Cardinal>
      </CardinalApolloProvider>
    </AuthProvider>
  )
}

export default App
