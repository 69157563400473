import React, { useContext, useEffect, useReducer } from 'react'
import {
  GetUserDocument,
  User,
  useGetSignInUserLazyQuery,
  useGetUserFollowerContentLazyQuery,
  Follower_Content,
} from '../gql/generated/graphql'
import { AuthStateContext, AuthenticationState } from './authContext'
import { CustomEmoteCategoryType, mergeUserFollowerEmojis } from './cardinalHelper'
import { useApolloClient } from '@apollo/client'
import { getAuth } from 'firebase/auth'

export enum BrowsingState {
  Authenticated = 'authenticated',
  Anonymous = 'anonymous',
  Loading = 'loading',
  Unconfirmed = 'unconfirmed',
}

const CardinalState: {
  user: User | undefined
  currentProfile: User | undefined
  homeScrollPosition: number
  lastDirectChat: string
  isMobileView: boolean
  browsingState: BrowsingState
  customEmoteCategories: CustomEmoteCategoryType[]
} = {
  user: undefined,
  currentProfile: undefined,
  homeScrollPosition: 0,
  lastDirectChat: '',
  isMobileView: false,
  browsingState: BrowsingState.Loading,
  customEmoteCategories: [],
}

export enum ActionType {
  SetUser = 'setUser',
  SetBrowsingState = 'setBrowsingState',
  SetCurrentProfile = 'setCurrentProfile',
  SetHomeScrollPosition = 'setHomeScrollPosition',
  SetLastDirectChat = 'setLastDirectChat',
  SetIsMobileView = 'setIsMobileView',
  SetCustomEmoteCategories = 'setCustomEmoteCategories',
}

type Action =
  | { type: ActionType.SetUser; payload: typeof CardinalState.user }
  | { type: ActionType.SetBrowsingState; payload: typeof CardinalState.browsingState }
  | { type: ActionType.SetCurrentProfile; payload: typeof CardinalState.currentProfile }
  | { type: ActionType.SetHomeScrollPosition; payload: typeof CardinalState.homeScrollPosition }
  | { type: ActionType.SetLastDirectChat; payload: typeof CardinalState.lastDirectChat }
  | { type: ActionType.SetIsMobileView; payload: typeof CardinalState.isMobileView }
  | { type: ActionType.SetCustomEmoteCategories; payload: typeof CardinalState.customEmoteCategories }

export const CardinalStateContext = React.createContext(CardinalState)
export const CardinalDispatchContext = React.createContext((_payload: Action) => {})

export const CardinalReducer = (state: typeof CardinalState, action: Action) => {
  switch (action.type) {
    case ActionType.SetUser:
      return {
        ...state,
        user: action.payload,
      }
    case ActionType.SetBrowsingState:
      return {
        ...state,
        browsingState: action.payload,
      }
    case ActionType.SetCurrentProfile:
      return {
        ...state,
        currentProfile: action.payload,
      }
    case ActionType.SetHomeScrollPosition:
      return {
        ...state,
        homeScrollPosition: action.payload,
      }
    case ActionType.SetLastDirectChat:
      return {
        ...state,
        lastDirectChat: action.payload,
      }
    case ActionType.SetIsMobileView:
      return {
        ...state,
        isMobileView: action.payload,
      }
    case ActionType.SetCustomEmoteCategories:
      return {
        ...state,
        customEmoteCategories: action.payload,
      }
    default:
      console.error(`Notice: Unhandled action: ${action}`)
      return state
  }
}

type CardinalProps = {
  children: JSX.Element
}

export const Cardinal = ({ children }: CardinalProps) => {
  const [state, dispatch] = useReducer(CardinalReducer, CardinalState)
  const { authUser, authenticationState } = useContext(AuthStateContext)
  const [getSignInUser] = useGetSignInUserLazyQuery()
  const [getUserFollowerContent] = useGetUserFollowerContentLazyQuery()
  const client = useApolloClient()

  // Add an observable to update the user if there are any changes.
  useEffect(() => {
    if (state.user?.handle) {
      const observable = client
        .watchQuery({
          query: GetUserDocument,
          variables: { handle: state.user?.handle, currentUserHandle: '' },
        })
        .subscribe({
          next: value => {
            dispatch({
              type: ActionType.SetUser,
              payload: value.data.user_by_pk as User,
            })
          },
        })

      return () => {
        observable.unsubscribe()
      }
    }
  }, [client, state.user?.handle])

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth < 751) {
        dispatch({
          type: ActionType.SetIsMobileView,
          payload: true,
        })
      } else {
        dispatch({
          type: ActionType.SetIsMobileView,
          payload: false,
        })
      }
    }

    checkWindowSize()
    window.addEventListener('resize', checkWindowSize)
  }, [])

  useEffect(() => {
    if (authenticationState === AuthenticationState.Loading) {
      dispatch({
        type: ActionType.SetBrowsingState,
        payload: BrowsingState.Loading,
      })
      return
    }

    if (authenticationState === AuthenticationState.Unconfirmed) {
      dispatch({
        type: ActionType.SetBrowsingState,
        payload: BrowsingState.Unconfirmed,
      })
      return
    }

    if (authenticationState === AuthenticationState.Anonymous) {
      dispatch({
        type: ActionType.SetBrowsingState,
        payload: BrowsingState.Anonymous,
      })
      dispatch({
        type: ActionType.SetUser,
        payload: undefined,
      })
      return
    }
  }, [authenticationState, dispatch])

  useEffect(() => {
    if (authUser?.email && authenticationState === AuthenticationState.Authenticated) {
      console.log('Getting user information...')

      getSignInUser({ variables: { email: authUser.email } })
        .then(response => {
          if (response.data) {
            console.log('Successfully retrieved user information...')
            dispatch({
              type: ActionType.SetUser,
              payload: response.data.user[0] as User,
            })
            dispatch({
              type: ActionType.SetBrowsingState,
              payload: BrowsingState.Authenticated,
            })

            getUserFollowerContent({ variables: { currentUserHandle: response.data.user[0].handle } })
              .then(res => {
                dispatch({
                  type: ActionType.SetCustomEmoteCategories,
                  payload: mergeUserFollowerEmojis(
                    res.data?.follower_content as Follower_Content[],
                    response?.data?.user[0] as User
                  ),
                })
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            throw new Error('Failed to retrieve user information. Signing user out...')
          }
        })
        .catch(error => {
          getAuth().signOut()
          console.log(error.message)
        })
    }
  }, [authUser, authenticationState, dispatch, getSignInUser])

  return (
    <CardinalStateContext.Provider value={state}>
      <CardinalDispatchContext.Provider value={dispatch}>{children}</CardinalDispatchContext.Provider>
    </CardinalStateContext.Provider>
  )
}
