import { useContext, useEffect, useState } from 'react'
import { User_Set_Input, useUpdateUserMutation } from '../../../gql/generated/graphql'
import './styles/themeEditor.css'

import { CardinalStateContext } from '../../../context/cardinal'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import { checkBackgroundType, tags, types } from '../../../utils/converterUtils'

import './styles/appBackgroundEditor.css'

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'

export function ThemeEditor() {
  const cardinalState = useContext(CardinalStateContext)
  const [updateUserMutation] = useUpdateUserMutation()

  async function setAccentColor(code: string) {
    const input: User_Set_Input = {
      app_theme_accent: code,
    }

    if (cardinalState.user?.handle) {
      await updateUserMutation({ variables: { handle: cardinalState.user?.handle, object: input } })
    }
  }

  ////////////////////////////////////////////////////

  const state = useContext(EditorStateContext)
  const dispatch = useContext(EditorDispatchContext)
  const [tagType, setTagType] = useState<tags | undefined>(undefined)
  const [blob, setBlob] = useState<File>()

  useEffect(() => {
    if (blob) {
      dispatch({
        type: ActionType.updateAppBackgroundBlob,
        payload: blob,
      })
      dispatch({
        type: ActionType.setAppBackground,
        payload: URL.createObjectURL(blob),
      })
      checkBackgroundTypeFromBlob(blob.type)
    }
    // eslint-disable-next-line
  }, [blob])

  useEffect(() => {
    let pastBannerIndex = cardinalState.user?.profile_media?.past_banners?.findIndex(item => item === state.banner)
    if (state.appBackgroundBlob === undefined) {
      setTagType(checkBackgroundType(state.appBackground))
    }

    if (state.banner === cardinalState.user?.banner || pastBannerIndex !== -1) {
      setBlob(undefined)
    }
    // eslint-disable-next-line
  }, [state.appBackground])

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setBlob(e.target.files[0])
    }
    e.target.value = ''
  }

  function checkBackgroundTypeFromBlob(url: string) {
    const reversedString = url.split('').reverse().join('')
    const reversedExtension = reversedString.split('/')[0]
    const extension = reversedExtension.split('').reverse().join('')

    setTagType(types.get(extension.toLocaleLowerCase()))
  }

  return (
    <>
      <h1 className="Tab-Header-Mobile">THEME</h1>
      <div className="Theme-Editor-Container">
        <h1>THEME</h1>
        <h2>Accent color:</h2>
        <div className="Emoji-Editor-Accent-Selection-List">
          <div
            className="Emoji-Editor-Accent-Selection-Item Emoji-Editor-Accent-Seiso"
            onClick={() => setAccentColor('Seiso')}
          />
          <div
            className="Emoji-Editor-Accent-Selection-Item Emoji-Editor-Accent-Sakura"
            onClick={() => setAccentColor('Sakura')}
          />
          <div
            className="Emoji-Editor-Accent-Selection-Item Emoji-Editor-Accent-MonMon"
            onClick={() => setAccentColor('MonMon')}
          />
          <div
            className="Emoji-Editor-Accent-Selection-Item Emoji-Editor-Accent-Blaze"
            onClick={() => setAccentColor('Blaze')}
          />
          <div
            className="Emoji-Editor-Accent-Selection-Item Emoji-Editor-Accent-Purp"
            onClick={() => setAccentColor('Purp')}
          />
        </div>

        <div className="App-Background-Editor-Container">
          <div className="App-Background-Editor-Flex">
            <div className="Upload-App-Background-Info">
              <div>
                <h2>App Background: Choose a background or upload a new one (aspect-ratio 16/9)</h2>
              </div>
              <div className="Upload-Avatar-Button Upload-App-Background-Button">
                <button onClick={() => document.getElementById('App-Background-Upload-Input')?.click()}>
                  <span>Upload a background</span>
                </button>

                <input
                  id="App-Background-Upload-Input"
                  type="file"
                  accept="image/* video/*"
                  onChange={onSelectFile}
                  hidden
                />
              </div>
            </div>
          </div>
          {state.appBackground !== undefined && state.appBackground.length > 0 && (
            <div className="App-Background-Preview-Container">
              {tagType === tags.video ? (
                <video
                  className="App-Background-Preview-Video"
                  playsInline
                  autoPlay
                  muted
                  loop
                  disablePictureInPicture
                  src={state.appBackground}
                />
              ) : (
                <img className="App-Background-Preview-Image" alt="" src={state.appBackground} />
              )}
            </div>
          )}

          {cardinalState.user?.profile_media?.past_app_backgrounds && (
            <div className="Past-Mini-Background-Container">
              <h2>Your Backgrounds</h2>
              <div className="Past-Mini-Background-Container-Inner">
                <div
                  className="Past-Mini-Background-Item-Remove"
                  onClick={() => dispatch({ type: ActionType.setAppBackground, payload: '' })}>
                  <DoNotDisturbIcon />
                </div>
                {cardinalState.user.profile_media.past_app_backgrounds?.map(
                  (item, key) =>
                    item.length > 0 && (
                      <div className="Past-Mini-Background-Item" key={key}>
                        {checkBackgroundType(item) === tags.video ? (
                          <video
                            className="Past-Mini-Background-Item-Video"
                            playsInline
                            muted
                            loop
                            disablePictureInPicture
                            src={item ? item + '#t=0.1' : undefined}
                            onMouseEnter={e => e.currentTarget.play()}
                            onMouseLeave={e => e.currentTarget.pause()}
                            onTouchStart={e => {
                              e.currentTarget.play()
                              e.currentTarget.focus()
                            }}
                            onBlur={e => e.currentTarget.pause()}
                            onClick={() => {
                              if (item) dispatch({ type: ActionType.setAppBackground, payload: item })
                            }}
                          />
                        ) : (
                          <img
                            className="App-Background-Preview-Image"
                            alt=""
                            src={item}
                            onClick={() => {
                              if (item) dispatch({ type: ActionType.setAppBackground, payload: item })
                            }}
                          />
                        )}
                      </div>
                    )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
