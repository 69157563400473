import { useContext, useEffect, useState } from 'react'
import { CardinalStateContext } from '../../../context/cardinal'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import { checkBackgroundType, tags, types } from '../../../utils/converterUtils'
import './styles/profileBackgroundEditor.css'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'

export function ProfileBackgroundEditor() {
  const cardinalState = useContext(CardinalStateContext)
  const state = useContext(EditorStateContext)
  const dispatch = useContext(EditorDispatchContext)
  const [tagType, setTagType] = useState<tags | undefined>(undefined)
  const [blob, setBlob] = useState<File>()

  useEffect(() => {
    if (blob) {
      dispatch({
        type: ActionType.updateProfileBackgroundBlob,
        payload: blob,
      })
      dispatch({
        type: ActionType.setProfileBackground,
        payload: URL.createObjectURL(blob),
      })
      checkBackgroundTypeFromBlob(blob.type)
    }
    // eslint-disable-next-line
  }, [blob])

  useEffect(() => {
    let pastBannerIndex = cardinalState.user?.profile_media?.past_banners?.findIndex(item => item === state.banner)
    setTagType(checkBackgroundType(state.profileBackground))
    if (state.banner === cardinalState.user?.banner || pastBannerIndex !== -1) {
      setBlob(undefined)
    }
    // eslint-disable-next-line
  }, [state.profileBackground])

  useEffect(() => {
    if (state.profileBackgroundBlob === undefined) {
      setTagType(checkBackgroundType(state.profileBackground))
    }
    // eslint-disable-next-line
  }, [state.profileBackgroundBlob])

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setBlob(e.target.files[0])
    }
  }

  function checkBackgroundTypeFromBlob(url: string) {
    const splitLink = url.split('/')
    const extension = splitLink[splitLink.length - 1]
    console.log(extension)

    setTagType(types.get(extension.toLocaleLowerCase()))
  }
  return (
    <>
      <h1 className="Tab-Header-Mobile">PROFILE BACKGROUND</h1>
      <div className="Profile-Background-Editor-Container">
        <div className="Profile-Background-Editor-Flex">
          <div className="Upload-Profile-Background-Info">
            <div>
              <h1>PROFILE BACKGROUND</h1>
              <h2>Choose a background or upload a new one (aspect-ratio 16/9)</h2>
            </div>
            <div className="Upload-Avatar-Button Upload-Profile-Background-Button">
              <button onClick={() => document.getElementById('Profile-Background-Upload-Input')?.click()}>
                <span>Upload a background</span>
              </button>

              <input
                id="Profile-Background-Upload-Input"
                type="file"
                accept="image/* video/*"
                onChange={onSelectFile}
                hidden
              />
            </div>
          </div>
        </div>
        {state.profileBackground !== undefined && state.profileBackground.length > 0 && (
          <div className="Profile-Background-Preview-Container">
            {tagType === tags.video ? (
              <video
                className="Profile-Background-Preview-Video"
                playsInline
                autoPlay
                loop
                muted
                disablePictureInPicture
                src={state.profileBackground}
              />
            ) : (
              <img className="Profile-Background-Preview-Image" alt="" src={state.profileBackground} />
            )}
          </div>
        )}

        {cardinalState.user?.profile_media?.past_profile_backgrounds && (
          <div className="Past-Mini-Background-Container">
            <h2>Your Backgrounds</h2>
            <div className="Past-Mini-Background-Container-Inner">
              <div
                className="Past-Mini-Background-Item-Remove"
                onClick={() => dispatch({ type: ActionType.setProfileBackground, payload: '' })}>
                <DoNotDisturbIcon />
              </div>
              {cardinalState.user.profile_media.past_profile_backgrounds?.map(
                (item, key) =>
                  item.length > 0 && (
                    <div className="Past-Mini-Background-Item" key={key}>
                      {checkBackgroundType(item) === tags.video ? (
                        <video
                          className="Past-Mini-Background-Item-Video"
                          playsInline
                          muted
                          loop
                          disablePictureInPicture
                          src={item ? item + '#t=0.1' : undefined}
                          onMouseEnter={e => e.currentTarget.play()}
                          onMouseLeave={e => e.currentTarget.pause()}
                          onTouchStart={e => {
                            e.currentTarget.play()
                            e.currentTarget.focus()
                          }}
                          onBlur={e => e.currentTarget.pause()}
                          onClick={() => {
                            if (item) dispatch({ type: ActionType.setProfileBackground, payload: item })
                          }}
                        />
                      ) : (
                        <img
                          className="App-Background-Preview-Image"
                          alt=""
                          src={item}
                          onClick={() => {
                            if (item) dispatch({ type: ActionType.setProfileBackground, payload: item })
                          }}
                        />
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
