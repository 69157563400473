import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded'
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove'
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import HeartBrokenIcon from '@mui/icons-material/HeartBroken'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import RepeatIcon from '@mui/icons-material/Repeat'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import moment from 'moment'
import { useContext, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Twemoji from 'react-twemoji'
import sanitizeHTML from 'sanitize-html'
import { PathNames } from '../../constants/pathNames'
import { CardinalStateContext } from '../../context/cardinal'
import {
  Post,
  User,
  useCreateBookmarkMutation,
  useCreatePostVoteMutation,
  useDeleteBookmarkMutation,
  useDeletePostMutation,
  useDeletePostVoteMutation,
} from '../../gql/generated/graphql'
import { deletePostVoteOptimisticResponse, insertPostVoteOptimisticResponse } from '../../gql/optimisticResponses'
import { ProfileAvatar } from '../profile/display/profileAvatar'
import { FeedType } from './feed'
import { PostMenu } from './modal/postMenu'
import { RepostMenu } from './modal/repostMenu'
import { CustomVideoElement } from '../../utils/customVideoElement'
import { sanitizeHtmlConf } from '../../utils/constants'
import './styles/post.css'
import { VerifiedCheck } from '../profile/display/verifiedCheck'
import { QuoteRepostItem } from './utils/quoteRepostItem'

interface FeedItemProps {
  id: string
  timestamp: string
  owner?: User
  text?: string | null
  gifUrl?: string | null
  imageUrls?: (string | null)[]
  videoUrl?: string | null
  replyCount?: number
  voteCount?: number
  shareCount?: number
  bookmarked?: boolean
  voted?: boolean
  itemType?: FeedType
  focusedItem?: boolean
  replyToHandle?: string
  sharedBy?: User
  quoteRepostData?: Post
  contentWarning?: boolean
}

export function FeedItem({
  id,
  timestamp,
  owner,
  text,
  gifUrl,
  imageUrls,
  videoUrl,
  replyCount,
  voteCount,
  shareCount,
  bookmarked,
  voted,
  itemType,
  focusedItem,
  replyToHandle,
  sharedBy,
  quoteRepostData,
  contentWarning,
}: FeedItemProps) {
  const location = useLocation()
  const postTextRef = useRef<HTMLDivElement>(null)
  const [postMenuOpen, setPostMenuOpen] = useState<boolean>(false)
  const [repostMenuOpen, setRepostMenuOpen] = useState<boolean>(false)
  const [disableContentWarning, setDisableContentWarning] = useState<boolean>(false)
  const [videoControlOverride, setVideoControlOverride] = useState<boolean>(false)

  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: '%ds',
      m: '%dm',
      mm: '%dm',
      h: '%dh',
      hh: '%dh',
      d: '%dd',
      dd: '%dd',
    },
  })

  const { user } = useContext(CardinalStateContext)
  const navigate = useNavigate()
  const [deletePostMutation] = useDeletePostMutation()

  const [createBookmarkMutation] = useCreateBookmarkMutation({
    variables: { post_id: id, owner_handle: user?.handle ?? '' },
  })
  const [deleteBookmarkMutation] = useDeleteBookmarkMutation({
    variables: { post_id: id, owner_handle: user?.handle ?? '' },
  })
  const [createPostVoteMutation] = useCreatePostVoteMutation({
    variables: { post_id: id, owner_handle: user?.handle ?? '' },
    optimisticResponse: insertPostVoteOptimisticResponse(id, user?.handle ?? '', voteCount ?? 0),
  })
  const [deletePostVoteMutation] = useDeletePostVoteMutation({
    variables: { post_id: id, owner_handle: user?.handle ?? '' },
    optimisticResponse: deletePostVoteOptimisticResponse(id, voteCount ?? 0),
  })

  function handleVoteOptionClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      if (!voted) {
        createPostVoteMutation().catch(reason => {
          console.log(reason)
        })
      } else {
        deletePostVoteMutation().catch(reason => {
          console.log(reason)
        })
      }
    } else {
      navigate(PathNames.Authentication)
    }
  }

  function handleBookmarkOptionClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      if (!bookmarked) {
        createBookmarkMutation().catch(reason => {
          console.log(reason)
        })
      } else {
        deleteBookmarkMutation().catch(reason => {
          console.log(reason)
        })
      }
    } else {
      navigate(PathNames.Authentication)
    }
  }

  function handleRepostOptionClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      setRepostMenuOpen(true)
    } else {
      navigate(PathNames.Authentication)
    }
  }

  async function handleDeletePost(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()

    let response = await deletePostMutation({ variables: { post_id: id } })
  }

  // useEffect(() => {
  //   const rects = postTextRef.current?.children[0]?.getClientRects()
  //   //console.log(postTextRef.current?.children[0]?.getClientRects())
  //   console.log(postTextRef.current?.children[0])
  //   //console.log(rects, rects?.length)

  //   if (rects) {
  //     const numberOfLines = (() => {
  //       let lines = 0
  //       for (let i = 0; i < rects.length; i++) {
  //         if (rects[i - 1]) {
  //           if (rects[i - 1].y !== rects[i].y) {
  //             lines++
  //           }
  //         } else {
  //           lines++
  //         }
  //       }
  //       return lines
  //     })()
  //     if (numberOfLines > 1) {
  //       console.log(numberOfLines)
  //     }
  //   }
  // }, [])

  return (
    <>
      <div className={`Post-Container ${focusedItem ? 'Focused-Post-Container' : ''}`}>
        <div
          className="Post-Container-Inner"
          onClick={(e: any) => {
            e.stopPropagation()
            e.preventDefault()
            let sel = window.getSelection()
            if (sel?.type !== 'Range') {
              if (e.target instanceof HTMLAnchorElement) {
                if (e.target.className === 'Post-Mention') {
                  navigate(`/profile/${e.target.getAttribute('data-seiso-miniprofile')}`)
                } else if (e.target.className === 'Post-Hashtag') {
                  navigate(`/hashtag/${e.target.innerText.replace('#', '')}`)
                }
              } else if (e.target.parentElement instanceof HTMLAnchorElement) {
                window.open(e.target.parentElement.href, '_blank')
              } else if (!focusedItem) {
                navigate(`/post/${owner?.handle}/${id}`)
              }
            }
          }}>
          {sharedBy && (
            <NavLink
              onClick={e => e.stopPropagation()}
              data-seiso-miniprofile={`${sharedBy.handle}`}
              to={`/profile/${sharedBy.handle}`}
              className="Post-Shared-By">
              <RepeatIcon style={{ transform: 'rotate(90deg)' }} />
              <Twemoji options={{ className: 'Post-Shared-By-Emoji' }}>
                {user?.handle === sharedBy.handle ? `Shared By You` : `Shared By ${sharedBy.username}`}
              </Twemoji>
            </NavLink>
          )}
          <div className="Post-Body">
            <div className="Post-Left">
              <ProfileAvatar user={owner} profileFrame />
            </div>
            <div className="Post-Right">
              <div className="Post-Header">
                <div className="Post-Info">
                  <NavLink
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    to={`/profile/${owner?.handle}`}
                    className="Post-User-Info">
                    <span className="Post-Displayname" data-seiso-miniprofile={owner?.handle}>
                      <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>{owner?.username}</Twemoji>
                      {owner?.is_verified && <VerifiedCheck />}
                    </span>

                    <span data-seiso-miniprofile={owner?.handle} className="Post-Username">
                      @{owner?.handle}
                    </span>
                  </NavLink>
                </div>
                <div className="Post-Timestamp">
                  <time dateTime={`${timestamp}`}>
                    {moment().diff(moment(timestamp), 'hours') >= 23
                      ? moment(timestamp).format('MMM Do')
                      : moment(timestamp).fromNow()}
                  </time>
                </div>
                {user && (
                  <>
                    <div
                      className="Post-Menu-Button"
                      onClick={e => {
                        e.stopPropagation()
                        setPostMenuOpen(true)
                      }}>
                      <MoreHorizOutlinedIcon />
                    </div>
                    {postMenuOpen && (
                      <PostMenu
                        deletePostFunction={handleDeletePost}
                        postOwner={owner?.handle ? owner?.handle : ''}
                        user={user}
                        postId={id}
                        closeMenuFunction={() => setPostMenuOpen(false)}
                      />
                    )}
                  </>
                )}
              </div>
              {replyToHandle && (
                <div className="Reply-Indicator">
                  Replying to{' '}
                  <NavLink
                    data-seiso-miniprofile={replyToHandle}
                    onClick={e => e.stopPropagation()}
                    to={`/profile/${replyToHandle}`}>
                    @{`${replyToHandle}`}
                  </NavLink>
                </div>
              )}
              <div className={`Post-Content`}>
                <div className="Post-Text">
                  <Twemoji options={{ className: 'Post-Emoji' }}>
                    <div
                      ref={postTextRef}
                      dangerouslySetInnerHTML={{ __html: text ? sanitizeHTML(text, sanitizeHtmlConf) : '' }}
                    />
                  </Twemoji>
                </div>
                {((imageUrls && imageUrls.length > 0) || videoUrl || gifUrl) && (
                  <div className="Post-Non-Text">
                    {contentWarning && !disableContentWarning && (
                      <div className="Post-Content-Content-Warning">
                        <div>
                          <VisibilityOffIcon style={{ fontSize: '5rem' }} />
                          <h1>Content Warning: The author has marked this post as containing sensitive content.</h1>
                          <button
                            className="Post-Content-Content-Warning-Show-Button"
                            onClick={e => {
                              e.stopPropagation()
                              setDisableContentWarning(true)
                            }}>
                            Show
                          </button>
                        </div>
                      </div>
                    )}
                    {imageUrls && imageUrls?.length > 0 && (
                      <>
                        {imageUrls.length === 1 ? (
                          imageUrls?.map((image, key) => (
                            <NavLink
                              className="Post-Image-Single"
                              onClick={e => e.stopPropagation()}
                              to={`/post/${owner?.handle}/${id}/image/1`}
                              state={{ background: location }}
                              key={key}>
                              <img className="Post-Image" src={image ? image : ''} alt="" />
                            </NavLink>
                          ))
                        ) : (
                          <div className="Post-Images-Grid">
                            {imageUrls?.slice(0, 4).map((image, key) => (
                              <NavLink
                                onClick={e => e.stopPropagation()}
                                to={`/post/${owner?.handle}/${id}/image/${key + 1}`}
                                state={{ background: location }}
                                className={
                                  imageUrls?.length === 2
                                    ? 'Post-Images-Grid-Item'
                                    : imageUrls?.length === 3 && key === 0
                                    ? 'Post-Images-Grid-Item'
                                    : imageUrls?.length === 3 && key > 0
                                    ? 'Post-Images-Grid-Item-Alt'
                                    : 'Post-Images-Grid-Item-Alt'
                                }
                                key={key}>
                                <img className="Post-Image-Alt" src={image ? image : ''} alt="" />
                              </NavLink>
                            ))}
                          </div>
                        )}
                      </>
                    )}

                    {videoUrl && (
                      <div className="Post-Video-Container">
                        {/* <CustomVideoElement
                        className="Post-Video"
                        innerClassName="Post-Video-Inner"
                        autoPlay
                        playsInline
                        muted
                        loop
                        src={videoUrl}
                        onClick={e => e.stopPropagation()}
                      /> */}
                        <video
                          className="Post-Video"
                          controls
                          autoPlay
                          playsInline
                          muted
                          loop
                          disablePictureInPicture
                          src={videoUrl}
                          onClick={e => {
                            console.log(e)
                            e.stopPropagation()
                          }}
                        />
                      </div>
                    )}
                    {gifUrl && <img className="Post-Image" src={gifUrl} draggable />}
                  </div>
                )}
                {quoteRepostData && (
                  <QuoteRepostItem
                    id={quoteRepostData?.id}
                    timestamp={quoteRepostData.created_at}
                    text={quoteRepostData.text}
                    imageUrls={quoteRepostData.image_urls}
                    gifUrl={quoteRepostData.gif_url}
                    videoUrl={quoteRepostData.video_url}
                    owner={quoteRepostData.owner}
                    quotePost
                  />
                )}
                <div className="Post-Options">
                  <div
                    className="Post-Option"
                    onClick={e => {
                      e.stopPropagation()
                      user
                        ? navigate('/create/post', {
                            state: {
                              replyData: { id: id, timestamp: timestamp, owner: owner, text: text },
                              feedType: FeedType.Replies,
                              background: location,
                            },
                          })
                        : navigate('/login')
                    }}>
                    <ChatBubbleOutlineRoundedIcon />
                    <span>{replyCount}</span>
                  </div>
                  <div className={`Post-Option ${voted && 'Post-Option-Active'}`} onClick={handleVoteOptionClick}>
                    {voted ? (
                      <>
                        <FavoriteRoundedIcon className="Full-Heart" />
                        <HeartBrokenIcon className="Break-Heart" />
                      </>
                    ) : (
                      <FavoriteBorderRoundedIcon />
                    )}
                    <span>{voteCount}</span>
                    {/* Possiblity of likes and dislikes? */}
                  </div>

                  <div className="Post-Option" onClick={handleRepostOptionClick}>
                    <RepeatIcon style={{ transform: 'rotate(90deg)' }} />

                    <span>{shareCount}</span>
                  </div>
                  {repostMenuOpen && user && (
                    <RepostMenu
                      postId={id}
                      openQuotePostModal={() =>
                        navigate('/create/post', {
                          state: {
                            quoteRepostData: {
                              id: id,
                              timestamp: timestamp,
                              owner: owner,
                              text: text,
                              image_urls: imageUrls,
                              video_url: videoUrl,
                              gif_url: gifUrl,
                            },
                            background: location,
                          },
                        })
                      }
                      closeMenuFunction={() => setRepostMenuOpen(false)}
                    />
                  )}
                  <div
                    className={`Post-Option ${bookmarked && 'Post-Option-Active'}`}
                    onClick={handleBookmarkOptionClick}>
                    {bookmarked ? (
                      <>
                        <BookmarkIcon className="Full-Bookmark" /> <BookmarkRemoveIcon className="Remove-Bookmark" />
                      </>
                    ) : (
                      <BookmarkBorderRoundedIcon />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FeedItem
