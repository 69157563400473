import { useContext } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { AuthStateContext, AuthenticationState } from '../../context/authContext'

export function ConfirmedRoute(props: { component: JSX.Element }) {
  const location = useLocation()
  const { authenticationState } = useContext(AuthStateContext)
  if (authenticationState === AuthenticationState.Unconfirmed) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  return props.component
}
