import { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import './styles/authentication.css'
import {
  AuthenticationAction,
  AuthBody,
  AuthBodyProps,
  AuthenticationFormField,
  AuthenticationTab,
  AuthenticationFlowState,
} from '../components/authentication'
import { AuthStateContext } from '../context/authContext'
import { CardinalStateContext } from '../context/cardinal'
import { getAuth } from 'firebase/auth'
import { LoadingModal } from '../components/loading/loadingModal'

export function Authentication() {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = getAuth()

  const { authUser, flowState } = useContext(AuthStateContext)
  const { user } = useContext(CardinalStateContext)
  const [tab, setTab] = useState<AuthenticationTab>(AuthenticationTab.SignIn)
  const [hideTabs, setHideTabs] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    auth
      .authStateReady()
      .then(async () => {
        await auth.currentUser?.getIdToken(true) //Force refresh token.
      })
      .finally(() => setLoading(false))
  }, [auth, setLoading])

  useEffect(() => {
    switch (flowState) {
      case AuthenticationFlowState.ConfirmingSignUp:
      case AuthenticationFlowState.EmailVerifySuccessConfirmation:
        setTab(AuthenticationTab.SignUp)
        setHideTabs(true)
        break
      case AuthenticationFlowState.RequestingResetPassword:
      case AuthenticationFlowState.SentResetPasswordEmail:
      case AuthenticationFlowState.ConfirmingResetPassword:
      case AuthenticationFlowState.PasswordResetSuccessConfirmation:
        setHideTabs(true)
        break
      case AuthenticationFlowState.None:
      default:
        setTab(AuthenticationTab.SignIn)
        setHideTabs(false)
        break
    }
  }, [flowState])

  const bodyContent: AuthBodyProps = useMemo(() => {
    switch (tab) {
      case AuthenticationTab.SignUp:
        if (flowState === AuthenticationFlowState.ConfirmingSignUp) {
          return {
            formFields: [],
            headerText: 'We have sent you a confirmation email. Please verify your account before continuing.',
            submitAction: {
              displayText: 'Resend Confirmation Email',
              action: AuthenticationAction.SendSignUpConfirmationEmail,
            },
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
          }
        }
        if (flowState === AuthenticationFlowState.EmailVerifySuccessConfirmation) {
          return {
            formFields: [],
            headerText: 'SUCCESS!',
            submitAction: { displayText: 'Go to home', action: AuthenticationAction.GoToHome },
          }
        }
        return {
          formFields: [
            { formFieldName: AuthenticationFormField.Email, type: 'email', placeholder: 'Email' },
            { formFieldName: AuthenticationFormField.Username, type: 'text', placeholder: 'Username' },
            { formFieldName: AuthenticationFormField.Password, type: 'password', placeholder: 'Password' },
            {
              formFieldName: AuthenticationFormField.ConfirmPassword,
              type: 'password',
              placeholder: 'Confirm Password',
            },
          ],
          submitAction: { displayText: 'Create Account', action: AuthenticationAction.SignUp },
          inputValidators: [
            {
              formFieldName: AuthenticationFormField.Username,
              regexExpression: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              errorMessage: 'Username cannot be an email',
            },
            {
              formFieldName: AuthenticationFormField.Username,
              regexExpression: /^(?![a-zA-Z0-9_-]+([_-]?[a-zA-Z0-9])*$)/,
              errorMessage: 'Username can only be alphanumeric characters, - or _',
            },
            {
              formFieldName: AuthenticationFormField.Username,
              regexExpression: /^(.{0,2}|.{16,})$/,
              errorMessage: 'Username can only be between 3-15 characters',
            },
          ],
          headerText: 'REGISTER',
        }
      case AuthenticationTab.SignIn:
      default:
        if (flowState === AuthenticationFlowState.RequestingResetPassword) {
          return {
            formFields: [{ formFieldName: AuthenticationFormField.Email, type: 'email', placeholder: 'Email' }],
            submitAction: { displayText: 'Send Email', action: AuthenticationAction.SendResetPasswordEmail },
            headerText: 'RESET PASSWORD',
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
          }
        }

        if (flowState === AuthenticationFlowState.SentResetPasswordEmail) {
          return {
            formFields: [],
            headerText:
              'Password reset email sent. Please follow the steps in the email to finish reseting your password.',
            submitAction: {
              displayText: 'Resend Email',
              action: AuthenticationAction.SendResetPasswordEmail,
            },
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
          }
        }

        if (flowState === AuthenticationFlowState.ConfirmingResetPassword) {
          return {
            formFields: [
              { formFieldName: AuthenticationFormField.Password, type: 'password', placeholder: 'Password' },
              {
                formFieldName: AuthenticationFormField.ConfirmPassword,
                type: 'password',
                placeholder: 'Confirm Password',
              },
            ],
            submitAction: { displayText: 'Reset Password', action: AuthenticationAction.ConfirmResetPassword },
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
            headerText: 'RESET PASSWORD',
          }
        }
        if (flowState === AuthenticationFlowState.PasswordResetSuccessConfirmation) {
          return {
            formFields: [],
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
            headerText: 'SUCCESS!',
          }
        }
        return {
          formFields: [
            { formFieldName: AuthenticationFormField.Email, type: 'email', placeholder: 'Email' },
            { formFieldName: AuthenticationFormField.Password, type: 'password', placeholder: 'Password' },
          ],
          submitAction: { displayText: 'Sign in', action: AuthenticationAction.SignIn },
          secondaryActions: [{ displayText: 'FORGOT YOUR PASSWORD?', action: AuthenticationAction.ResetPassword }],
          headerText: 'WELCOME BACK!',
        }
    }
  }, [tab, flowState])

  const from = useMemo(() => {
    const state = location.state as { from: string }

    if (state && state.from) {
      return state.from
    }

    return '/'
  }, [location])

  useEffect(() => {
    if (authUser?.emailVerified && user) {
      navigate(from, { replace: true })
    }
  }, [authUser, user, navigate, from])

  return (
    <>
      <div className="Login-Register-Container">
        {loading ? (
          <LoadingModal header="Loading.." />
        ) : (
          <>
            <AuthBody {...bodyContent} />
            {!hideTabs && (
              <div>
                <div
                  onClick={() => setTab(AuthenticationTab.SignIn)}
                  className={tab === AuthenticationTab.SignIn ? 'AuthenticationTab-Hidden' : 'AuthenticationTab'}>
                  Back to Sign In
                </div>
                <div
                  onClick={() => setTab(AuthenticationTab.SignUp)}
                  className={tab === AuthenticationTab.SignUp ? 'AuthenticationTab-Hidden' : 'AuthenticationTab'}>
                  Need an account?
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
