import './styles/browse.css'
import { LazyQueryHookOptions } from '@apollo/client'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ProfileAvatar } from '../components/profile/display/profileAvatar'
import { NavLink, useNavigate } from 'react-router-dom'
import { sanitizeHtmlConf } from '../utils/constants'
import sanitizeHTML from 'sanitize-html'
import { StreamProvider } from '../gql/scalars'
import {
  useGetTrendingHashtagsLazyQuery,
  Order_By,
  GetTrendingHashtagsQuery,
  GetTrendingHashtagsQueryVariables,
  useGetLiveUsersLazyQuery,
  GetLiveUsersQueryVariables,
  GetLiveUsersQuery,
  User,
  ListPostsQuery,
  ListPostsQueryVariables,
  useListPostsLazyQuery,
  Post,
} from '../gql/generated/graphql'
import { useContext, useEffect, useMemo } from 'react'
import Twemoji from 'react-twemoji'
import { BrowsingState, CardinalStateContext } from '../context/cardinal'
import { FeedItem } from '../components/feed/feedItem'
import { FeedType } from '../components/feed/feed'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'

export function Browse() {
  const navigate = useNavigate()
  const [getLiveUsers, { data: liveUserData }] = useGetLiveUsersLazyQuery()
  const [getTrendingHashtags, { data, loading, fetchMore, refetch }] = useGetTrendingHashtagsLazyQuery()
  const [listPosts, { data: postData }] = useListPostsLazyQuery()
  const { user, browsingState } = useContext(CardinalStateContext)
  let queryOptions: LazyQueryHookOptions<GetTrendingHashtagsQuery, GetTrendingHashtagsQueryVariables> = {
    variables: {
      limit: 10,
      filter: { created_at: { _gte: '2023-03-22T01:03:22.249838+00:00' } },
      order_by: { hashtagName: { hashtaged_posts_aggregate: { count: Order_By.Desc } } },
    },
  }
  let trendingPostQueryOptions: LazyQueryHookOptions<ListPostsQuery, ListPostsQueryVariables> = {
    variables: {
      limit: 5,

      order_by: { created_at: Order_By.Desc },
      currentUserHandle: browsingState === BrowsingState.Authenticated ? user?.handle : '',
    },
  }
  let liveUserQueryOptions: LazyQueryHookOptions<GetLiveUsersQuery, GetLiveUsersQueryVariables> = {
    variables: {
      limit: 6,
      order_by: { followers_aggregate: { count: Order_By.Desc } },
    },
  }

  //   useEffect(() => {
  //     getTrendingHashtags(queryOptions)
  //   }, [])

  useEffect(() => {
    async function test() {
      let data = await fetch(
        'https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=Ks-_Mh1QhMc&id=ZtaaLBL9GZo&id=jfKfPfyJRdk&key=AIzaSyCFLkWbU0ieuEA36BPP13EWSyKdTszkgeA'
      )
      console.log(data.json())
    }
    test()
  }, [])

  useEffect(() => {
    getLiveUsers(liveUserQueryOptions)
    listPosts(trendingPostQueryOptions)
  }, [])

  const liveUsers = useMemo(() => {
    return liveUserData?.user.map((user, index) => (
      <div
        className="Browse-Live-Profiles-Preview-Item"
        style={{
          backgroundImage: `url(${
            user.stream_provider === StreamProvider.Twitch
              ? `https://static-cdn.jtvnw.net/previews-ttv/live_user_${user.twitch_connection_id?.toLocaleLowerCase()}-1920x1080.jpg`
              : `http://img.youtube.com/vi/${user.youtube_connection_id}/maxresdefault.jpg`
          })`,
        }}
        onClick={() => navigate(`/profile/${user.handle}`)}
        key={index}>
        <div className="Browse-Live-Profiles-Preview-Item-Fade"></div>
        <div className="Browse-Live-Profiles-Preview-Item-Status">
          <Twemoji options={{ className: 'twemoji' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: user.status_message ? sanitizeHTML(user.status_message, sanitizeHtmlConf) : '',
              }}
            />
          </Twemoji>
        </div>
        <div className="Browse-Live-Profiles-Preview-Item-Info-Displayname">
          <ProfileAvatar size="Small" disableLink disableMiniProfile disableStatus user={user as User} />
          <span>
            <Twemoji options={{ className: 'Browse-Live-Profiles-Preview-Item-Info-Displayname-Emoji' }}>
              {user.username}
            </Twemoji>
          </span>
        </div>
        <div className="Browse-Live-Profiles-Preview-Item-Stream-Status">
          Live on{' '}
          <span className={`Browse-Live-Profiles-Preview-Item-Stream-Status-${user.stream_provider}`}>
            {user.stream_provider}
          </span>
        </div>
      </div>
    ))
  }, [liveUserData])

  let hashtagItems = Array(10)
    .fill(undefined)
    .map((_value, index) => {
      return (
        <div className="Browse-Popular-Hashtags-Item" onClick={() => navigate(`/hashtag/anime`)} key={index}>
          <div className="Browse-Popular-Hashtags-Item-Top">#anime</div>
          <div className="Browse-Popular-Hashtags-Item-Bottom">25.7k posts - 30.5k followers</div>
        </div>
      )
    })

  const feedItems = useMemo(() => {
    return postData?.post.map((post, index) => {
      if (post.repost_post) {
        return (
          <FeedItem
            id={post.repost_post.id}
            timestamp={post.repost_post.created_at}
            owner={post.repost_post.owner as User}
            text={post.repost_post.text}
            gifUrl={post.repost_post.gif_url}
            imageUrls={post.repost_post.image_urls}
            videoUrl={post.repost_post.video_url}
            replyCount={post.repost_post.replies_aggregate.aggregate?.count}
            voteCount={post.repost_post.votes_aggregate.aggregate?.count}
            shareCount={
              (post.repost_post.quote_reposts_aggregate.aggregate?.count ?? 0) +
              (post.repost_post.reposts_aggregate.aggregate?.count ?? 0)
            }
            voted={post.repost_post.votes.length > 0 && post.repost_post.votes[0]?.owner_handle === user?.handle}
            bookmarked={
              post.repost_post.bookmarked_posts.length > 0 &&
              post.repost_post.bookmarked_posts[0]?.owner_handle === user?.handle
            }
            itemType={FeedType.Post}
            key={index}
            sharedBy={post.owner as User}
          />
        )
      } else {
        return (
          <FeedItem
            id={post.id}
            timestamp={post.created_at}
            owner={post.owner as User}
            text={post.text}
            gifUrl={post.gif_url}
            imageUrls={post.image_urls}
            videoUrl={post.video_url}
            replyCount={post.replies_aggregate.aggregate?.count}
            voteCount={post.votes_aggregate.aggregate?.count}
            shareCount={
              (post.quote_reposts_aggregate.aggregate?.count ?? 0) + (post.reposts_aggregate.aggregate?.count ?? 0)
            }
            voted={post.votes.length > 0 && post.votes[0]?.owner_handle === user?.handle}
            bookmarked={post.bookmarked_posts.length > 0 && post.bookmarked_posts[0]?.owner_handle === user?.handle}
            itemType={FeedType.Post}
            key={index}
            quoteRepostData={post.quote_repost_post as Post}
          />
        )
      }
    })
  }, [postData])

  return (
    <div className="Browse-Container">
      <div className="Browse-Container-Inner">
        <NavBackButtonHeader title={'Browse'} />
        {/* <div className="Thread-Back-Button">
          <NavLink
            to="#"
            onClick={() => {
              navigate(-1)
            }}>
            <ArrowBackIcon />
          </NavLink>
          <span className="Thread-Title">Browse</span>
        </div> */}
        {liveUsers && liveUsers?.length > 0 && (
          <>
            <div className="Browse-Preview-Header">
              <span>Live Users</span>
            </div>
            <div className="Browse-Live-Profiles-Preview">
              {liveUsers}
              {liveUsers}
              {liveUsers}
            </div>
          </>
        )}
        <div className="Browse-Preview-Header">
          <span>Trending Hashtags</span>
        </div>
        <div className="Browse-Popular-Hashtags">{hashtagItems}</div>
        <div className="Browse-Preview-Header">
          <span>Trending Posts</span>
        </div>
        <div className="Trending-Posts-Preview">{feedItems}</div>
      </div>
    </div>
  )
}
