import { useContext } from 'react'
import { matchPath, NavLink, useLocation } from 'react-router-dom'
import { PathNames } from '../../constants/pathNames'
import { CardinalStateContext } from '../../context/cardinal'
import { StreamProvider } from '../../gql/scalars'
import './styles/sidebar.css'
import { Trending } from './trending'
import { Welcome } from './welcome'
import { LiveProfiles } from './liveProfiles'
import { PostRelevantPeople } from './postRelevantPeople'
import { User } from '../../gql/generated/graphql'

interface SidebarProps {
  postSidebar?: boolean
  postMentions?: User[]
}

export function Sidebar({ postSidebar, postMentions }: SidebarProps) {
  const { currentProfile } = useContext(CardinalStateContext)
  const location = useLocation()
  const showChat =
    (matchPath({ path: PathNames.Profile }, location.pathname) ||
      matchPath({ path: PathNames.ProfileTab }, location.pathname)) &&
    (currentProfile?.stream_provider === StreamProvider.Twitch ||
      currentProfile?.stream_provider === StreamProvider.Youtube)

  return (
    <>
      <div className="Sidebar-Container">
        <div className="Sidebar-Container-Inner">
          {!postSidebar ? (
            <div className="Sidebar-Container-Item">
              <h2 className="Sidebar-Container-Item-Header">Welcome</h2>
              <Welcome />
            </div>
          ) : (
            postMentions && (
              <div className="Sidebar-Container-Item">
                <h2 className="Sidebar-Container-Item-Header">Relevant Users</h2>
                <PostRelevantPeople users={postMentions} />
              </div>
            )
          )}
          <LiveProfiles />

          {/* <div className="Sidebar-Container-Item">
              <h2 className="Gradient-Title">You might like</h2>
              <Recommendation />
              <NavLink to="#" className="Sidebar-Showmore-Link">
                SEE MORE
              </NavLink>
            </div> */}

          <div className="Sidebar-Container-Item">
            <h2 className="Sidebar-Container-Item-Header">Game News/Updates</h2>
            <Trending />
            <NavLink to="/explore/trending" className="Sidebar-Showmore-Link">
              SEE MORE
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}
