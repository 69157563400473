import { $getSelection, $isElementNode, $isTextNode, EditorState, LexicalEditor, LexicalNode } from 'lexical'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { TextNode } from 'lexical'

import { useEffect, useState } from 'react'

import { TwemojiNode, $createTwemojiNode } from '../../nodes/TwemojiNode'
import regex from './regex'

import { PointType, GridSelection, NodeSelection, RangeSelection } from 'lexical/LexicalSelection'

function findAndTransformEmoji(node: TextNode): null | TextNode {
  const text = node.getTextContent()

  const emojiData = text.trimEnd().match(regex)

  if (emojiData)
    for (let i = 0; i < emojiData.length; i++) {
      let targetNode: TextNode

      const index = text.indexOf(emojiData[i])

      if (index > 0) {
        ;[, targetNode] = node.splitText(index) as TextNode[]
      } else {
        ;[targetNode] = node.splitText(index, index + emojiData[i].length) as TextNode[]
      }

      const emojiNode = $createTwemojiNode(emojiData[i])

      targetNode.replace(emojiNode)

      const selection: RangeSelection | NodeSelection | GridSelection | null = $getSelection()
      // //@ts-ignore
      // $moveSelectionPointToEndElement(selection!.anchor, emojiNode);
      // //@ts-ignore
      // $moveSelectionPointToEndElement(selection!.focus, emojiNode);
      //@ts-ignore
      const anchor = selection!.anchor
      //@ts-ignore
      const focus = selection!.focus

      if ((parseInt(anchor.key) - 1).toString() === targetNode.__key) {
        $moveSelectionPointToEndElement(anchor, emojiNode)
      }
      if ((parseInt(focus.key) - 1).toString() === targetNode.__key) {
        $moveSelectionPointToEndElement(focus, emojiNode)
      }

      return emojiNode
    }

  return null
}

function textNodeTransform(node: TextNode, selection: RangeSelection | NodeSelection | GridSelection | null): void {
  let targetNode: TextNode | null = node

  while (targetNode !== null) {
    if (!targetNode.isSimpleText()) {
      return
    }

    targetNode = findAndTransformEmoji(targetNode)
  }
}

function useEmojis(editor: LexicalEditor): void {
  const [selection, setSelection] = useState<RangeSelection | NodeSelection | GridSelection | null>(null)
  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      setSelection(editorState.read(() => $getSelection()))
    })

    if (!editor.hasNodes([TwemojiNode])) {
      throw new Error('EmojisPlugin: EmojiNode not registered on editor')
    }

    return editor.registerNodeTransform(TextNode, node => textNodeTransform(node, selection))
  }, [editor])
}

export default function TwemojiPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  useEmojis(editor)
  return null
}

function selectPointOnNodeElement(point: PointType, node: LexicalNode): void {
  let key = node.__key
  let offset = point.offset

  let type: 'element' | 'text' = 'element'
  if (!$isElementNode(node)) {
    const nextSibling = node.getNextSibling()
    if ($isTextNode(nextSibling)) {
      const parentNode = node.getParent()
      if (parentNode) {
        key = parentNode.__key
        offset = nextSibling.getIndexWithinParent() + 1
      }
    } else {
      const parentNode = node.getParent()
      if (parentNode) {
        key = parentNode.__key
        offset = node.getIndexWithinParent() + 1
      }
    }
  }

  point.set(key, offset, type)
}

function $moveSelectionPointToEndElement(point: PointType, node: LexicalNode): void {
  if ($isElementNode(node)) {
    const lastNode = node.getLastDescendant()
    if ($isElementNode(lastNode) || $isTextNode(lastNode)) {
      selectPointOnNodeElement(point, lastNode)
    } else {
      selectPointOnNodeElement(point, node)
    }
  } else {
    selectPointOnNodeElement(point, node)
  }
}
