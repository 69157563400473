import { useContext, useEffect, useMemo } from 'react'
import { GetPostQuery, GetPostQueryVariables, Order_By, useGetPostLazyQuery } from '../../generated/graphql'
import { BrowsingState, CardinalStateContext } from '../../../context/cardinal'
import { LazyQueryHookOptions } from '@apollo/client'

export function useGetPost(id?: string) {
  const { user, browsingState } = useContext(CardinalStateContext)
  const [getPost, { data, loading, fetchMore }] = useGetPostLazyQuery()

  const queryOptions: LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables> = useMemo(() => {
    return {
      variables: {
        id: id,
        limit: 20,
        offset: 0,
        order_by: { created_at: Order_By.Desc },
        currentUserHandle: user?.handle ?? '',
      },
      fetchPolicy: 'cache-and-network',
    }
  }, [user?.handle, id])

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading && id) {
      getPost(queryOptions)
    }
  }, [browsingState, id, getPost, queryOptions])

  return {
    data,
    loading,
    fetchMore: () =>
      fetchMore({ variables: { ...queryOptions.variables, offset: data?.post_by_pk?.replies?.length ?? 0 } }),
  }
}
