import { useContext, useEffect, useRef, useState } from 'react'
import { getAuth } from 'firebase/auth'
import { AuthStateContext } from '../context/authContext'

interface AuthorizationHeader {
  authorization: String
}

const THREE_MINUTES_IN_MS = 180000

export function useAuthHeader() {
  const { authUser } = useContext(AuthStateContext)
  const [header, setHeader] = useState<AuthorizationHeader | null>()
  const refreshTimer = useRef<NodeJS.Timer | undefined>()

  async function setHeaderWithToken() {
    getAuth()
      .currentUser?.getIdTokenResult()
      .then(idTokenResult => {
        const expirationDate = new Date(idTokenResult.expirationTime)
        const refreshInterval = expirationDate.getTime() - Date.now() - THREE_MINUTES_IN_MS

        if (refreshTimer.current) {
          console.log('Stoping token refresh interval')
          clearInterval(refreshTimer.current)
        }

        refreshTimer.current = setInterval(() => {
          console.log('Refreshing expired token')
          setHeaderWithToken()
        }, refreshInterval)
      })
      .catch(error => {
        // TODO: Handle error
        if (refreshTimer.current) {
          clearInterval(refreshTimer.current)
        }

        console.log(error)
      })
  }

  useEffect(() => {
    if (authUser) {
      setHeaderWithToken()
    }

    return () => {
      if (refreshTimer.current) {
        console.log('Stoping token refresh interval')
        clearInterval(refreshTimer.current)
      }
    }
  }, [authUser])

  useEffect(() => {
    const unsubscribe = getAuth().onIdTokenChanged(user => {
      if (user) {
        user
          .getIdToken()
          .then(token => {
            setHeader({ authorization: `Bearer ${token}` })
          })
          .catch(error => {
            console.log(error)
            setHeader(null)
          })
      }
    })

    return () => unsubscribe()
  }, [])

  return header
}
