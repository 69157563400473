import { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Feed, FeedType } from '../components/feed/feed'
import { FeedItem } from '../components/feed/feedItem'
import { CardinalStateContext } from '../context/cardinal'
import { Post as GraphqlPost, User } from '../gql/generated/graphql'
import './styles/thread.css'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'
import { useGetPost } from '../gql/hooks/queries/useGetPost'
import { Sidebar } from '../components/sidebar/sidebar'

export function Post() {
  const { owner_id, post_id } = useParams()
  const { user } = useContext(CardinalStateContext)
  const { data, loading, fetchMore } = useGetPost(post_id)

  const feedItems = useMemo(() => {
    return data?.post_by_pk?.replies.map((reply, index) => {
      return (
        <FeedItem
          id={reply.id}
          timestamp={reply.created_at}
          owner={reply.owner as User}
          text={reply.text}
          gifUrl={reply.gif_url}
          imageUrls={reply.image_urls}
          videoUrl={reply.video_url}
          replyCount={reply.replies_aggregate.aggregate?.count}
          voteCount={reply.votes_aggregate.aggregate?.count}
          shareCount={
            (reply.reposts_aggregate.aggregate?.count ?? 0) + (reply.quote_reposts_aggregate.aggregate?.count ?? 0)
          }
          voted={reply.votes.length > 0 && reply.votes[0].owner_handle === user?.handle}
          bookmarked={reply.bookmarked_posts.length > 0 && reply.bookmarked_posts[0]?.owner_handle === user?.handle}
          itemType={FeedType.Replies}
          contentWarning={reply.mature_content}
          replyToHandle={reply.reply_to_post?.owner_handle}
          key={index}
        />
      )
    })
  }, [data])

  const focusedItem = data?.post_by_pk && (
    <FeedItem
      id={data.post_by_pk.id}
      timestamp={data.post_by_pk.created_at}
      owner={data.post_by_pk.owner as User}
      text={data.post_by_pk.text}
      gifUrl={data.post_by_pk.gif_url}
      imageUrls={data.post_by_pk.image_urls}
      videoUrl={data.post_by_pk.video_url}
      replyCount={data.post_by_pk.replies_aggregate.aggregate?.count}
      voteCount={data.post_by_pk.votes_aggregate.aggregate?.count}
      shareCount={
        (data.post_by_pk.reposts_aggregate.aggregate?.count ?? 0) +
        (data.post_by_pk.quote_reposts_aggregate.aggregate?.count ?? 0)
      }
      voted={data.post_by_pk.votes?.length > 0 && data.post_by_pk.votes[0]?.owner_handle === user?.handle}
      bookmarked={
        data.post_by_pk.bookmarked_posts.length > 0 &&
        data.post_by_pk.bookmarked_posts[0]?.owner_handle === user?.handle
      }
      itemType={FeedType.Post}
      contentWarning={data.post_by_pk.mature_content}
      key={data.post_by_pk.id}
      replyToHandle={data.post_by_pk.reply_to_post?.owner_handle}
      quoteRepostData={data.post_by_pk.quote_repost_post as GraphqlPost}
      focusedItem
    />
  )

  return (
    <>
      <div className="Thread-Container">
        <NavBackButtonHeader title="Post" />

        <Feed
          loading={loading}
          items={feedItems}
          focusedItem={focusedItem}
          fetchMore={fetchMore}
          feedType={FeedType.Replies}
          noItemsMessage="There are no replies yet, be the first!"
        />
      </div>

      <Sidebar
        postSidebar
        postMentions={
          data?.post_by_pk?.owner && [
            ...(data?.post_by_pk?.post_mentions.map(item => item.user as User) ?? null),
            (data?.post_by_pk?.quote_repost_post?.owner as User) ?? null,
            data?.post_by_pk?.owner as User,
          ]
        }
      />
    </>
  )
}
