import { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ProfileActivity } from '../components/profile/profileActivity'
import { GalleryShowcase } from '../components/profile/showcases/galleryShowcase'
import { ImageShowcase } from '../components/profile/showcases/imageShowcase'
import { InfoShowcase } from '../components/profile/showcases/infoShowcase'
import { PanelShowcase } from '../components/profile/showcases/panelShowcase'
import { VideoShowcase } from '../components/profile/showcases/videoShowcase'
import { SocialsShowcase } from '../components/profile/showcases/socialsShowcase'
import { ActionType, BrowsingState, CardinalDispatchContext, CardinalStateContext } from '../context/cardinal'
import { User, useFollowMutation, useUnFollowMutation } from '../gql/generated/graphql'
import { ProfileHeader } from '../components/profile/profileHeader'
import {
  GalleryShowcase as GraphqlGalleryShowcase,
  ImageShowcase as GraphqlImageShowcase,
  InfoShowcase as GraphqlInfoShowcase,
  PanelShowcase as GraphqlPanelShowcase,
  SocialsShowcase as GraphqlSocialsShowcase,
  ShowcaseType,
  VideoShowcase as GraphqlVideoShowcase,
} from '../gql/scalars'
import './styles/profile.css'
import { Loading } from '../components/loading/loadingExports'
import { useGetUser } from '../gql'

export function Profile() {
  const dispatch = useContext(CardinalDispatchContext)
  const { user, browsingState, isMobileView } = useContext(CardinalStateContext)
  const { profile_id, profile_tab } = useParams()

  const [profile, setProfile] = useState<User | undefined>(undefined)
  const { data, loading, refetch } = useGetUser(profile_id)
  const [unFollowUser] = useUnFollowMutation()
  const [followUser] = useFollowMutation()
  const [following, setFollowing] = useState(false)

  useEffect(() => {
    // window.scrollTo(0, 0)

    if (data) {
      dispatch({
        type: ActionType.SetCurrentProfile,
        payload: data.user_by_pk as User,
      })
      setProfile(data.user_by_pk as User)
      setFollowing(data?.user_by_pk?.followers && data?.user_by_pk?.followers.length > 0 ? true : false)
    }
  }, [data, dispatch])

  // useEffect(() => {
  //   console.log(profile_id)
  //   refetch({ handle: profile_id })
  //   console.log(data)
  // }, [profile_id])

  async function follow() {
    if (profile?.handle && user) {
      await followUser({
        variables: {
          currentUserHandle: user.handle,
          handleToFollow: profile.handle,
        },
      })
      setFollowing(true)
    }
  }
  async function unFollow() {
    if (profile?.handle && user) {
      await unFollowUser({
        variables: {
          currentUserHandle: user.handle,
          handleToUnFollow: profile.handle,
        },
      })
      setFollowing(false)
    }
  }

  return (
    <>
      {loading || browsingState === BrowsingState.Loading ? (
        <Loading />
      ) : (
        <>
          {profile ? (
            <div className="Profile-Page">
              <div className="Profile-Page-Center-Content">
                <ProfileHeader
                  profile={profile}
                  isMobileView={isMobileView}
                  user={user}
                  following={following}
                  follow={follow}
                  unFollow={unFollow}
                />

                <div className="Profile-Body">
                  {profile_tab?.toLocaleLowerCase() === 'activity' && <ProfileActivity profile={profile} />}
                  {profile &&
                    profile_tab?.toLocaleLowerCase() !== 'activity' &&
                    profile.profile_tabs
                      ?.filter(
                        tab =>
                          tab!.name!.toLocaleLowerCase() === profile_tab ||
                          (!profile_tab && tab!.name!.toLocaleLowerCase() === 'home')
                      )
                      .map((item, key) =>
                        item && item?.showcases.length > 0 ? (
                          item!.showcases!.map((showcase, key) => (
                            <div className="Profile-Content-Item" key={key}>
                              {showcase?.title && showcase.description && (
                                <div className="Profile-Custom-Header">
                                  {showcase?.title && (
                                    <div className="Profile-Custom-Header-Top">{showcase?.title}</div>
                                  )}
                                  {showcase?.description && (
                                    <div className="Profile-Custom-Header-Desc">{showcase?.description}</div>
                                  )}
                                </div>
                              )}

                              {showcase?.type === ShowcaseType.Images && (
                                <ImageShowcase imageShowcase={showcase as GraphqlImageShowcase} />
                              )}
                              {showcase?.type === ShowcaseType.Video && (
                                <VideoShowcase videoShowcase={showcase as GraphqlVideoShowcase} />
                              )}
                              {showcase?.type === ShowcaseType.Info && (
                                <InfoShowcase infoShowcase={showcase as GraphqlInfoShowcase} />
                              )}
                              {showcase?.type === ShowcaseType.Gallery && (
                                <GalleryShowcase galleryShowcase={showcase as GraphqlGalleryShowcase} />
                              )}
                              {showcase?.type === ShowcaseType.Panel && (
                                <PanelShowcase panelShowcase={showcase as GraphqlPanelShowcase} />
                              )}
                              {showcase?.type === ShowcaseType.Socials && (
                                <SocialsShowcase socialsShowcase={showcase as GraphqlSocialsShowcase} />
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="No-Showcases-Message" key={key}>
                            There are no showcases here yet
                          </div>
                        )
                      )}
                </div>
              </div>
            </div>
          ) : (
            <div className="Profile-Page-None-Found">Profile Not Found..</div>
          )}
        </>
      )}
    </>
  )
}
