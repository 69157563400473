import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'

import './styles/navNotificationMenu.css'
import { ClickAwayListener } from '../components/modal/clickAwayListener'

interface NavNotificationMenuProps {
  closeMenuFunction: () => void
  data?: any
  notificationCount?: number
}

export function NavNotificationMenu({ closeMenuFunction, data, notificationCount }: NavNotificationMenuProps) {
  const [messageCount, setMessageCount] = useState(0)
  const [mentionCount, setMentionCount] = useState(0)
  const [replyCount, setReplyCount] = useState(0)

  useEffect(() => {
    setMessageCount(data.user_notifications.filter((item: any) => item.type === 'message').length)
    setMentionCount(data.user_notifications.filter((item: any) => item.type === 'mention').length)
    setReplyCount(data.user_notifications.filter((item: any) => item.type === 'reply').length)
  }, [data])

  return (
    <>
      {/* <div className="Modal-Block-Interaction-BG" /> */}
      <ClickAwayListener onClickAway={() => closeMenuFunction()} id="Nav-Notification-Menu">
        <div className="Nav-Notification-Menu">
          <div className="Nav-Notification-Menu-Options">
            <NavLink
              to={`/notifications/all`}
              className="Nav-Notification-Menu-Option"
              onClick={() => closeMenuFunction()}>
              View All Notifications {notificationCount ? notificationCount > 0 && `(${notificationCount})` : ''}
            </NavLink>
            <NavLink to={`/messages`} className="Nav-Notification-Menu-Option" onClick={() => closeMenuFunction()}>
              Messages {messageCount > 0 && `(${messageCount})`}
            </NavLink>
            <NavLink
              to="/notifications/mentions"
              className="Nav-Notification-Menu-Option"
              onClick={() => closeMenuFunction()}>
              Mentions {mentionCount > 0 && `(${mentionCount})`}
            </NavLink>
            <NavLink
              to="/notifications/replies"
              className="Nav-Notification-Menu-Option"
              onClick={() => closeMenuFunction()}>
              Replies {replyCount > 0 && `(${replyCount})`}
            </NavLink>
            <NavLink
              to="/notifications/overwatch"
              className="Nav-Notification-Menu-Option"
              onClick={() => closeMenuFunction()}>
              Overwatch
            </NavLink>
          </div>
        </div>
      </ClickAwayListener>
    </>
  )
}
