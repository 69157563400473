import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react'
import {
  AuthenticationAction,
  AuthenticationFlowState,
  AuthenticationFormField,
  IAuthenticationAction,
  IAuthenticationFormData,
  IInputValidator,
  ITabFormField,
} from './auth_interfaces'
import { AuthFormField } from './authFormField'
import {
  AuthContextActionType,
  AuthDispatchContext,
  AuthenticationState,
  SignUpRequest,
} from '../../context/authContext'
import { LoadingModal } from '../loading/loadingModal'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  User as FirebaseUser,
  applyActionCode,
  confirmPasswordReset,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { useLocation, useNavigate } from 'react-router-dom'

export interface AuthBodyProps {
  formFields: ITabFormField[]
  submitAction?: IAuthenticationAction
  inputValidators?: IInputValidator[]
  secondaryActions?: IAuthenticationAction[]
  headerText?: string
  footerActions?: IAuthenticationAction[]
}

export function AuthBody({
  formFields,
  submitAction,
  inputValidators,
  secondaryActions,
  headerText,
  footerActions,
}: AuthBodyProps) {
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [user, setUser] = useState<FirebaseUser>()
  const [email, setEmail] = useState<string>()
  const [confirmation, setConfirmation] = useState<string>()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(useLocation().search)
  const mode = searchParams.get('mode')
  const oobCode = searchParams.get('oobCode')

  const dispatch = useContext(AuthDispatchContext)
  const signUp = httpsCallable(getFunctions(), 'signUp')
  const auth = getAuth()

  useEffect(() => {
    if (oobCode) {
      switch (mode) {
        case 'resetPassword':
          dispatch({
            type: AuthContextActionType.SetFlowState,
            payload: AuthenticationFlowState.ConfirmingResetPassword,
          })
          break
        case 'recoverEmail':
          //TODO: Display email recovery handler and UI.
          break
        case 'verifyEmail':
          setLoading(true)
          if (!auth.currentUser) {
            setError('Please Sign In again to verify your email.')
            dispatch({
              type: AuthContextActionType.SetFlowState,
              payload: AuthenticationFlowState.None,
            })
            setLoading(false)
            break
          } else {
            setError(undefined)
          }

          if (auth.currentUser?.emailVerified) {
            dispatch({
              type: AuthContextActionType.SetFlowState,
              payload: AuthenticationFlowState.EmailVerifySuccessConfirmation,
            })
            setLoading(false)
            break
          }
          setLoading(true)
          applyActionCode(auth, oobCode)
            .then(() => {
              dispatch({
                type: AuthContextActionType.SetFlowState,
                payload: AuthenticationFlowState.EmailVerifySuccessConfirmation,
              })
              dispatch({
                type: AuthContextActionType.SetAuthenticationState,
                payload: AuthenticationState.Authenticated,
              })
            })
            .catch(error => {
              console.log(error)
              setError("Couldn't verify link. Please request a new one and try again later.")
            })
            .finally(() => setLoading(false))
          break
        default:
          setError("Couldn't verify link. Please request a new one and try again later.")
      }
    }
  }, [mode, oobCode, auth.currentUser])

  useEffect(() => {
    const passwordField = document.getElementById(AuthenticationFormField.Password) as HTMLInputElement
    const confirmPasswordField = document.getElementById(AuthenticationFormField.ConfirmPassword) as HTMLInputElement

    if (passwordField && confirmPasswordField) {
      if (
        submitAction?.action === AuthenticationAction.SignUp ||
        submitAction?.action === AuthenticationAction.ConfirmResetPassword
      ) {
        function validatePassword() {
          if (passwordField.value.length < 6) {
            setError('Password must be at least 6 characters')
            setDisabled(true)
          } else if (passwordField.value !== confirmPasswordField.value) {
            setError('Password does not match')
            setDisabled(true)
          } else {
            setError(undefined)
            setDisabled(false)
          }
        }

        passwordField.onchange = validatePassword
        confirmPasswordField.onkeyup = validatePassword
      } else {
        passwordField.onchange = null
        confirmPasswordField.onkeyup = null

        setError(undefined)
        setDisabled(false)
      }
    } else {
      setDisabled(false)
    }
  }, [submitAction?.action])

  function hasInputErrors(formData: IAuthenticationFormData) {
    if (inputValidators) {
      for (const validator of inputValidators) {
        if (formData[validator.formFieldName]?.match(validator.regexExpression)) {
          setError(validator.errorMessage)
          return true
        }
      }
    }

    setLoading(true)
    return false
  }

  function handleOnAction(event: BaseSyntheticEvent, action?: AuthenticationAction) {
    event.preventDefault()
    setError(undefined)
    setConfirmation(undefined)

    const formData: IAuthenticationFormData = {}
    if (event.type === 'submit') {
      new FormData(event.currentTarget).forEach((value, key) => {
        formData[key as AuthenticationFormField] = value as string
      })
    }

    switch (action) {
      case AuthenticationAction.SignUp:
        if (formData.username && formData.email && formData.password && formData.confirmPassword) {
          if (hasInputErrors(formData)) {
            break
          }

          //TODO: Add a profanity filter
          const request: SignUpRequest = {
            username: formData.username,
            email: formData.email,
            password: formData.password,
          }
          signUp(request)
            .then(success => {
              if (success) {
                signInWithEmailAndPassword(auth, formData.email ?? '', formData.password ?? '').then(credentials => {
                  setUser(credentials.user)
                  sendEmailVerification(credentials.user).then(() => {
                    dispatch({
                      type: AuthContextActionType.SetFlowState,
                      payload: AuthenticationFlowState.ConfirmingSignUp,
                    })
                  })
                })
              }
            })
            .catch(e => {
              if (e.message) {
                setError(e.message)
              }
            })
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.SendSignUpConfirmationEmail:
        const currentUser = user ?? auth.currentUser
        if (currentUser) {
          setLoading(true)
          sendEmailVerification(currentUser)
            .catch(e => setError(e.message))
            .finally(() => {
              setLoading(false)
              setConfirmation('Email sent!')
            })
        }
        break
      case AuthenticationAction.SignIn:
        if (formData.email && formData.password) {
          if (hasInputErrors(formData)) {
            break
          }

          signInWithEmailAndPassword(auth, formData.email, formData.password)
            .then(credentials => {
              if (credentials.user && !credentials.user.emailVerified && !oobCode) {
                dispatch({
                  type: AuthContextActionType.SetFlowState,
                  payload: AuthenticationFlowState.ConfirmingSignUp,
                })
              }
            })
            .catch(e => {
              setError(e.message)
            })
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.ResetPassword:
        dispatch({
          type: AuthContextActionType.SetFlowState,
          payload: AuthenticationFlowState.RequestingResetPassword,
        })
        break
      case AuthenticationAction.SendResetPasswordEmail:
        const currentEmail = formData.email ?? email
        if (currentEmail) {
          if (hasInputErrors(formData)) {
            break
          }

          sendPasswordResetEmail(auth, currentEmail)
            .then(() => {
              setEmail(currentEmail)
              dispatch({
                type: AuthContextActionType.SetFlowState,
                payload: AuthenticationFlowState.SentResetPasswordEmail,
              })
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.ConfirmResetPassword:
        if (oobCode && formData.password && formData.confirmPassword) {
          if (hasInputErrors(formData)) {
            break
          }

          confirmPasswordReset(auth, oobCode, formData.password)
            .then(() =>
              dispatch({
                type: AuthContextActionType.SetFlowState,
                payload: AuthenticationFlowState.PasswordResetSuccessConfirmation,
              })
            )
            .catch(e => setError(e.message))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.GoToSignIn:
        dispatch({
          type: AuthContextActionType.SetFlowState,
          payload: AuthenticationFlowState.None,
        })

        //Going back to login means we want to sign in again so sign out first.
        if (auth.currentUser) {
          auth.signOut()
        }
        break
      case AuthenticationAction.GoToHome:
        navigate('/', { replace: true })
        break
      default:
        break
    }
  }

  return (
    <div>
      <div>
        {loading && <LoadingModal header="Loading.." />}
        <form onSubmit={e => handleOnAction(e, submitAction?.action)}>
          {headerText && <h1>{headerText}</h1>}
          {formFields.map((field, key) => (
            <AuthFormField key={`${key}-${field.formFieldName}`} {...field} />
          ))}
          {error && <div className="Auth-Body-Error">{error}</div>}
          {confirmation && <div className="Auth-Body-Confirmation">{confirmation}</div>}
          {secondaryActions?.map((secondaryAction, key) => (
            <div
              className="Auth-Body-Secondary-Text"
              key={`${key}-${secondaryAction.displayText}`}
              onClick={e => handleOnAction(e, secondaryAction.action)}>
              {secondaryAction.displayText}
            </div>
          ))}
          {submitAction && (
            <input type="submit" className="Auth-Submit" value={submitAction.displayText} disabled={disabled} />
          )}

          {footerActions &&
            footerActions?.map((footerAction, key) => (
              <div
                className="Auth-Body-Footer-Text"
                key={`${key}-${footerAction.displayText}`}
                onClick={e => handleOnAction(e, footerAction.action)}>
                {footerAction.displayText}
              </div>
            ))}
        </form>
      </div>
    </div>
  )
}
