import { useContext } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { AuthStateContext, AuthenticationState } from '../../context/authContext'
import { ConfirmedRoute } from './confirmedRoute'

export function ProtectedRoute(props: { component: JSX.Element }) {
  const location = useLocation()
  const { authenticationState } = useContext(AuthStateContext)
  if (authenticationState === AuthenticationState.Anonymous) {
    return <ConfirmedRoute component={props.component} />
  }
  return props.component
}
