import { User } from '../../gql/generated/graphql'
import { ListProfileItem } from '../profile/display/listProfileItem'

interface PostRelevantPeopleProps {
  users: User[]
}

export function PostRelevantPeople({ users }: PostRelevantPeopleProps) {
  return (
    <>
      {users.length > 0 && (
        <div>
          {users.map(
            (item: User, key: number) =>
              item !== null && (
                <div key={key}>
                  <ListProfileItem avatarSize="XSmall" user={item} index={key} disableSocialLinks />
                </div>
              )
          )}
        </div>
      )}
    </>
  )
}
