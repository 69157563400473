import { useContext, useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { AvatarEditor } from '../components/profile/editor/avatarEditor'
import { BannerEditor } from '../components/profile/editor/bannerEditor'
import { GeneralEditor } from '../components/profile/editor/generalEditor'
import { MiniProfileEditor } from '../components/profile/editor/miniProfileEditor'
import { ProfileShowcaseEditor } from '../components/profile/editor/profileShowcaseEditor'
import { SaveIndicator } from '../components/profile/editor/saveIndicator'
import { SocialsEditor } from '../components/profile/editor/socialsEditor'
import { ProfileBackgroundEditor } from '../components/profile/editor/profileBackgroundEditor'
import { EmojiEditor } from '../components/profile/editor/emojiEditor'
import { PrivacyEditor } from '../components/profile/editor/privacyEditor'
import { ThemeEditor } from '../components/profile/editor/themeEditor'
import { CardinalStateContext } from '../context/cardinal'
import { uploadToStorage } from '../utils/cloudflareR2'
import { LoadingModal, Loading } from '../components/loading/loadingExports'
import {
  ActionType as EditorActionType,
  EditorDispatchContext,
  EditorState,
  EditorStateContext,
  ShowcaseContext,
} from '../context/editorContext'
import { User, User_Set_Input, useUpdateUserMutation } from '../gql/generated/graphql'
import {
  GalleryShowcase,
  ImageShowcase,
  PanelShowcase,
  ProfileMedia,
  ProfileTab,
  Showcase,
  ShowcaseType,
} from '../gql/scalars'
// import usePrompt from '../utils/blockPath'
import MenuIcon from '@mui/icons-material/Menu'

import './styles/profile.css'
import './styles/profileSettings.css'
import { ClickAwayListener } from '../components/modal/clickAwayListener'

export function ProfileSettings() {
  const { settings_type } = useParams()
  const editorDispatch = useContext(EditorDispatchContext)
  const editorState = useContext(EditorStateContext)
  const cardinalState = useContext(CardinalStateContext)
  const [originState, setOriginState] = useState<EditorState | undefined>(undefined)
  const [changes, setChanges] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [savingProfile, setSavingProfile] = useState(false)
  const [updateUserMutation] = useUpdateUserMutation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)

  useEffect(() => {
    if (cardinalState.user) {
      let update: EditorState = {
        displayname: cardinalState.user.username,
        handle: cardinalState.user.handle,
        profileDesc: cardinalState.user.status_message ?? '',
        avatar: cardinalState.user.profile_image ?? '',
        banner: cardinalState.user.banner ?? '',
        profileBackground: cardinalState.user.profile_background ?? '',
        appBackground: cardinalState.user.app_background ?? '',
        miniProfileBackground: cardinalState.user.mini_profile_background ?? '',
        profileTabs: cardinalState.user.profile_tabs,
        socialLinks: cardinalState.user.social_links,
        profileAvatarBlob: undefined,
        bannerBlob: undefined,
        miniProfileBackgroundBlob: undefined,
        appBackgroundBlob: undefined,
        profileBackgroundBlob: undefined,
      }
      setOriginState(update)
      editorDispatch({
        type: EditorActionType.updateState,
        payload: update,
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (cardinalState.user) {
      let update: EditorState = {
        displayname: cardinalState.user.username,
        handle: cardinalState.user.handle,
        profileDesc: cardinalState.user.status_message ?? '',
        avatar: cardinalState.user.profile_image ?? '',
        banner: cardinalState.user.banner ?? '',
        profileBackground: cardinalState.user.profile_background ?? '',
        appBackground: cardinalState.user.app_background ?? '',
        miniProfileBackground: cardinalState.user.mini_profile_background ?? '',
        profileTabs: cardinalState.user.profile_tabs,
        socialLinks: cardinalState.user.social_links,
        profileAvatarBlob: undefined,
        bannerBlob: undefined,
        miniProfileBackgroundBlob: undefined,
        appBackgroundBlob: undefined,
        profileBackgroundBlob: undefined,
      }
      setOriginState(update)
      editorDispatch({
        type: EditorActionType.updateState,
        payload: update,
      })
    }
    // eslint-disable-next-line
  }, [cardinalState.user])

  useEffect(() => {
    if (originState && editorState && JSON.stringify(originState) === JSON.stringify(editorState)) {
      setChanges(false)
    } else if (originState) {
      setChanges(true)
    }

    if (originState && editorState && cardinalState.user) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [editorState, originState])

  async function UpdateDB(
    updatedTabs: (ProfileTab | null)[] | null | undefined,
    profileAvatar: string,
    banner: string,
    miniProfileBackground: string,
    profileBackground: string,
    appBackground: string
  ) {
    if (!editorState.handle) {
      throw new Error('Notice - Handle field cannot be empty!')
    }

    let pastAvatars = cardinalState.user?.profile_media?.past_avatars
      ? cardinalState.user?.profile_media?.past_avatars
      : []
    let pastAvatarsIndex = cardinalState.user?.profile_media?.past_avatars?.findIndex(
      (item: string) => item === profileAvatar
    )

    let pastBanners = cardinalState.user?.profile_media?.past_banners
      ? cardinalState.user?.profile_media?.past_banners
      : []
    let pastBannersIndex = cardinalState.user?.profile_media?.past_banners?.findIndex((item: string) => item === banner)

    let pastMiniBackgrounds = cardinalState.user?.profile_media?.past_mini_backgrounds
      ? cardinalState.user?.profile_media?.past_mini_backgrounds
      : []
    let pastProfileBackgrounds = cardinalState.user?.profile_media?.past_profile_backgrounds
      ? cardinalState.user?.profile_media?.past_profile_backgrounds
      : []
    let pastAppBackgrounds = cardinalState.user?.profile_media?.past_app_backgrounds
      ? cardinalState.user?.profile_media?.past_app_backgrounds
      : []
    let pastMiniBackgroundsIndex = cardinalState.user?.profile_media?.past_mini_backgrounds?.findIndex(
      (item: string) => item === miniProfileBackground
    )
    let pastProfileBackgroundsIndex = cardinalState.user?.profile_media?.past_profile_backgrounds?.findIndex(
      (item: string) => item === profileBackground
    )
    let pastAppBackgroundsIndex = cardinalState.user?.profile_media?.past_app_backgrounds?.findIndex(
      (item: string) => item === appBackground
    )
    let profileMedia: ProfileMedia = {
      past_avatars: pastAvatarsIndex ?? -1 === -1 ? [...pastAvatars, profileAvatar] : pastAvatars,
      past_banners: pastBannersIndex ?? -1 === -1 ? [...pastBanners, banner] : pastBanners,
      past_mini_backgrounds:
        pastMiniBackgroundsIndex ?? -1 === -1 ? [...pastMiniBackgrounds, miniProfileBackground] : pastMiniBackgrounds,
      past_profile_backgrounds:
        pastProfileBackgroundsIndex ?? -1 === -1
          ? [...pastProfileBackgrounds, profileBackground]
          : pastProfileBackgrounds,
      past_app_backgrounds:
        pastAppBackgroundsIndex ?? -1 === -1 ? [...pastAppBackgrounds, appBackground] : pastAppBackgrounds,
    }

    const input: User_Set_Input = {
      handle: editorState.handle !== cardinalState.user?.handle ? editorState.handle : undefined,
      username: editorState.displayname,
      status_message: editorState.profileDesc,
      profile_image: profileAvatar,
      mini_profile_background: miniProfileBackground,
      display_mini_profile_social_links: true,
      banner: banner,
      profile_media: profileMedia,
      profile_tabs: updatedTabs,
      social_links: editorState.socialLinks,
      app_background: appBackground,
      profile_background: profileBackground,
    }

    if (cardinalState.user?.handle) {
      return updateUserMutation({
        variables: { handle: cardinalState.user?.handle, object: input },
        update: (cache, { data }) => {
          cache.modify({
            id: cache.identify(cardinalState.user!),
            fields: {
              handle: () => data?.update_user_by_pk?.handle,
            },
          })
        },
      }).then(response => {
        if (response?.data?.update_user_by_pk) {
          setOriginState(editorState)
          editorDispatch({
            type: EditorActionType.updateState,
            payload: editorState,
          })
        }
      })
    }
  }

  async function Save() {
    setSavingProfile(true)
    let tabs = editorState.profileTabs
    let profileAvatar = editorState.avatar
    let banner = editorState.banner
    let miniProfileBackground = editorState.miniProfileBackground
    let profileBackground = editorState.profileBackground
    let appBackground = editorState.appBackground
    let updatedAvatarLink = editorState.profileAvatarBlob
      ? await uploadToStorage([editorState.profileAvatarBlob])
      : undefined
    let updatedBannerLink = editorState.bannerBlob ? await uploadToStorage([editorState.bannerBlob]) : undefined
    let updatedMiniProfileBackgroundLink = editorState.miniProfileBackgroundBlob
      ? await uploadToStorage([editorState.miniProfileBackgroundBlob])
      : undefined
    let updatedProfileBackgroundLink = editorState.profileBackgroundBlob
      ? await uploadToStorage([editorState.profileBackgroundBlob])
      : undefined
    let updatedAppBackgroundLink = editorState.appBackgroundBlob
      ? await uploadToStorage([editorState.appBackgroundBlob])
      : undefined

    /*Normal Upload */

    if (tabs) {
      for await (let tab of tabs) {
        if (tab?.showcases) {
          let showcase: (Showcase & ShowcaseContext) | null
          for await (showcase of tab.showcases) {
            if (showcase && showcase.blobs) {
              let updatedLinks = await uploadToStorage(showcase.blobs.map(blob => blob.blob))
              let i = 0
              if (updatedLinks)
                for await (let updatedLink of updatedLinks) {
                  let blob = showcase.blobs[i]
                  if (
                    showcase.type === ShowcaseType.Images &&
                    blob &&
                    blob.imagePosition !== undefined &&
                    blob.itemKey !== undefined
                  ) {
                    ;(showcase as ImageShowcase).items![blob.itemKey]!.images![blob.imagePosition] = updatedLink
                  } else if (showcase.type === ShowcaseType.Gallery && blob && blob.imagePosition !== undefined) {
                    ;(showcase as GalleryShowcase).images![blob.imagePosition] = updatedLink
                  } else if (showcase.type === ShowcaseType.Panel && blob && blob.imagePosition !== undefined) {
                    ;(showcase as PanelShowcase).items![blob.imagePosition]!.image = updatedLink
                  }
                  i++
                }

              delete showcase.blobs
            }
          }
        }
      }
    }

    if (updatedAvatarLink) {
      profileAvatar = updatedAvatarLink[0]
    }
    if (updatedBannerLink) {
      banner = updatedBannerLink[0]
    }
    if (updatedMiniProfileBackgroundLink) {
      miniProfileBackground = updatedMiniProfileBackgroundLink[0]
    }
    if (updatedProfileBackgroundLink) {
      profileBackground = updatedProfileBackgroundLink[0]
    }
    if (updatedAppBackgroundLink) {
      appBackground = updatedAppBackgroundLink[0]
    }

    return UpdateDB(tabs, profileAvatar, banner, miniProfileBackground, profileBackground, appBackground).finally(() =>
      setSavingProfile(false)
    )
  }

  function HardReset() {
    if (originState)
      editorDispatch({
        type: EditorActionType.updateState,
        payload: originState,
      })
  }

  return (
    <>
      {savingProfile && <LoadingModal header="Updating.." subheader="Updating your profile. Please wait." />}
      <div className="Profile-Settings-Container">
        <div onClick={() => setMobileMenuOpen(true)} className="Profile-Settings-Mobile-Menu-Button">
          <MenuIcon />
        </div>
        <div className="Profile-Settings-Flex">
          <ClickAwayListener onClickAway={() => setMobileMenuOpen(false)} id="Profile-Settings-Left-Mobile-Menu">
            <div className={`Profile-Settings-Left ${mobileMenuOpen ? 'Profile-Settings-Left-Mobile-Menu-Open' : ''}`}>
              <div className="Profile-Settings-Spacer">Profile</div>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/general"
                className={settings_type === 'general' ? 'Setting-Link-Active' : ''}>
                General
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/avatar"
                className={settings_type === 'avatar' ? 'Setting-Link-Active' : ''}>
                Avatar
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/profile-background"
                className={settings_type === 'profile-background' ? 'Setting-Link-Active' : ''}>
                Profile Background
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/banner"
                className={settings_type === 'banner' ? 'Setting-Link-Active' : ''}>
                Banner
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/socials"
                className={settings_type === 'socials' ? 'Setting-Link-Active' : ''}>
                Socials
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/mini-profile"
                className={settings_type === 'mini-profile' ? 'Setting-Link-Active' : ''}>
                Mini Profile
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/emoji"
                className={settings_type === 'emoji' ? 'Setting-Link-Active' : ''}>
                Emoji
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/showcases"
                className={settings_type === 'showcases' ? 'Setting-Link-Active' : ''}>
                Showcases
              </NavLink>
              <div className="Profile-Settings-Spacer">App & Privacy</div>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/app-theme"
                className={settings_type === 'app-theme' ? 'Setting-Link-Active' : ''}>
                Theme
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/filters"
                className={settings_type === 'filters' ? 'Setting-Link-Active' : ''}>
                Filters
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/privacy"
                className={settings_type === 'privacy' ? 'Setting-Link-Active' : ''}>
                Privacy
              </NavLink>
            </div>
          </ClickAwayListener>
          <div className="Profile-Settings-Right">
            {loading ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <SaveIndicator show={changes} OnResetClick={HardReset} OnSaveClick={Save} />
                {settings_type === 'general' && <GeneralEditor />}
                {settings_type === 'showcases' && <ProfileShowcaseEditor />}
                {settings_type === 'avatar' && <AvatarEditor />}
                {settings_type === 'banner' && <BannerEditor />}
                {settings_type === 'mini-profile' && <MiniProfileEditor />}
                {settings_type === 'socials' && <SocialsEditor />}
                {settings_type === 'profile-background' && <ProfileBackgroundEditor />}
                {settings_type === 'emoji' && <EmojiEditor />}
                {settings_type === 'app-theme' && <ThemeEditor />}
                {settings_type === 'privacy' && <PrivacyEditor />}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
