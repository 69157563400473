import { User, useFollowMutation, useUnFollowMutation } from '../../../gql/generated/graphql'
import { ProfileAvatar } from './profileAvatar'
import { VerifiedCheck } from './verifiedCheck'
import Twemoji from 'react-twemoji'
import { sanitizeHtmlConf } from '../../../utils/constants'
import sanitizeHTML from 'sanitize-html'
import { useNavigate } from 'react-router-dom'
import { getProviderIcon } from '../../../utils/statusUtils'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'

import './styles/listProfileItem.css'
import { useContext } from 'react'
import { CardinalStateContext } from '../../../context/cardinal'

interface ListProfileItemProps {
  user: User
  index: number
  disableSocialLinks?: boolean
  disableFollowButton?: boolean
  disableMessageButton?: boolean
  avatarSize?: string
}

export function ListProfileItem({
  user,
  index,
  disableSocialLinks,
  disableFollowButton,
  disableMessageButton,
  avatarSize,
}: ListProfileItemProps) {
  const { user: currentUser } = useContext(CardinalStateContext)
  const navigate = useNavigate()
  const [unFollowUser] = useUnFollowMutation()
  const [followUser] = useFollowMutation()

  async function follow(handle: string) {
    if (currentUser) {
      await followUser({
        variables: {
          currentUserHandle: currentUser.handle,
          handleToFollow: handle,
        },
      })
    }
  }
  async function unFollow(handle: string) {
    if (currentUser) {
      await unFollowUser({
        variables: {
          currentUserHandle: currentUser.handle,
          handleToUnFollow: handle,
        },
      })
    }
  }

  return (
    <div
      className="Profile-List-Item"
      id="Profile-List-Item"
      onClick={e => {
        let sel = window.getSelection()
        if (sel?.type === 'Caret') navigate(`/profile/${user.handle}`)
      }}
      key={index}>
      <ProfileAvatar size={avatarSize} user={user} />
      <div className="Profile-List-Info-Container">
        <div className="Profile-List-Info-Top">
          <div className="Profile-List-Info-Left">
            <span className="Profile-List-Username">
              <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>
                {user?.username}
                {user?.is_verified && <VerifiedCheck />}
              </Twemoji>
            </span>
            <span className="Profile-List-Handle">@{user?.handle}</span>
          </div>
          <div className="Profile-Socials Fade_In Profile-List-Socials">
            {!disableSocialLinks &&
              user?.social_links?.slice(0, 5).map((socialLink: any, key: number) => (
                <a
                  href={socialLink?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="Social-Icon-Container Profile-List-Social-Icon-Container"
                  key={key}>
                  <div className={`Social-Icon ${socialLink?.provider}-Icon`}>
                    <img alt="" src={getProviderIcon(socialLink?.provider) ?? socialLink?.icon} />
                  </div>
                </a>
              ))}
            {!disableMessageButton && user?.handle !== currentUser?.handle && (
              <div
                onClick={e => {
                  e.stopPropagation()
                  let sel = window.getSelection()
                  const id = [user?.handle, currentUser?.handle].sort().join('-')
                  if (sel?.type === 'Caret') {
                    navigate(`/messages/${id}`)
                  }
                }}
                className="Mini-Profile-Message-Button">
                <EmailOutlinedIcon />
              </div>
            )}
            {!disableFollowButton && (
              <div
                className={` Mini-Profile-Follow-Button ${
                  user?.followers && user.followers.length > 0 && 'Profile-Follow-Button-Active'
                }`}
                onClick={e => {
                  e.stopPropagation()
                  if (user?.handle === user?.handle) {
                    navigate('/settings/general')
                  } else if (user?.followers && user.followers.length === 0) follow(user.handle)
                  else if (user?.followers && user.followers.length > 0) unFollow(user.handle)
                }}>
                {user?.handle === currentUser?.handle ? (
                  'Edit Profile'
                ) : user?.followers && user.followers.length > 0 ? (
                  <>
                    <FavoriteOutlinedIcon className="Full-Heart" />
                    <HeartBrokenOutlinedIcon className="Break-Heart" />
                  </>
                ) : (
                  'Follow'
                )}
              </div>
            )}
          </div>
        </div>
        <div className="Profile-List-Status">
          <Twemoji options={{ className: 'Post-Emoji' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: user?.status_message ? sanitizeHTML(user?.status_message, sanitizeHtmlConf) : '',
              }}
            />
          </Twemoji>
        </div>
      </div>
    </div>
  )
}
