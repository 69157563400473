import { $getSelection, $isElementNode, $isTextNode, EditorState, LexicalEditor, LexicalNode } from 'lexical'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { TextNode } from 'lexical'
import { useEffect, useState } from 'react'

import { $createEmojiNode, EmojiNode } from '../../nodes/EmojiNode'
import { PointType, GridSelection, NodeSelection, RangeSelection } from 'lexical/LexicalSelection'

function findAndTransformEmoji(node: TextNode, customEmojis: any): null | TextNode {
  const text = node.getTextContent()

  const emojis: Map<string, [string, string]> = new Map(
    customEmojis?.map((item: any) => [item.id, [item.id, item.url]])
  )

  for (let i = 0; i < text.length; i++) {
    const emojiData = emojis.get(text[i]) || emojis.get(text.slice(i, i + 31).trimEnd())

    if (emojiData !== undefined) {
      const [emojiID, emojiRef] = emojiData
      let targetNode: TextNode

      if (i === 0) {
        ;[targetNode] = node.splitText(i + 31) as TextNode[]
      } else {
        ;[, targetNode] = node.splitText(i, i + 31) as TextNode[]
      }

      const emojiNode = $createEmojiNode(emojiID, emojiRef)

      targetNode.replace(emojiNode)
      const selection: RangeSelection | NodeSelection | GridSelection | null = $getSelection()
      // //@ts-ignore
      // $moveSelectionPointToEndElement(selection!.anchor, emojiNode);
      // //@ts-ignore
      // $moveSelectionPointToEndElement(selection!.focus, emojiNode);
      //@ts-ignore
      const anchor = selection!.anchor
      //@ts-ignore
      const focus = selection!.focus

      if ((parseInt(anchor.key) - 1).toString() === targetNode.__key) {
        $moveSelectionPointToEndElement(anchor, emojiNode)
      }
      if ((parseInt(focus.key) - 1).toString() === targetNode.__key) {
        $moveSelectionPointToEndElement(focus, emojiNode)
      }

      return emojiNode
    }
  }

  return null
}

function textNodeTransform(
  node: TextNode,
  selection: RangeSelection | NodeSelection | GridSelection | null,
  emojis: any
): void {
  let targetNode: TextNode | null = node

  while (targetNode !== null) {
    if (!targetNode.isSimpleText()) {
      return
    }

    targetNode = findAndTransformEmoji(targetNode, emojis)
  }
}

function useEmojis(editor: LexicalEditor, emojis: any): void {
  const [selection, setSelection] = useState<RangeSelection | NodeSelection | GridSelection | null>(null)
  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      setSelection(editorState.read(() => $getSelection()))
    })

    if (!editor.hasNodes([EmojiNode])) {
      throw new Error('EmojisPlugin: EmojiNode not registered on editor')
    }

    return editor.registerNodeTransform(TextNode, node => textNodeTransform(node, selection, emojis))
  }, [editor, emojis])
}

export default function EmojisPlugin({ emojis }: { emojis: any }): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  useEmojis(editor, emojis)
  return null
}

function selectPointOnNodeElement(point: PointType, node: LexicalNode): void {
  let key = node.__key
  let offset = point.offset

  let type: 'element' | 'text' = 'element'
  if (!$isElementNode(node)) {
    const nextSibling = node.getNextSibling()
    if ($isTextNode(nextSibling)) {
      const parentNode = node.getParent()
      if (parentNode) {
        key = parentNode.__key
        offset = nextSibling.getIndexWithinParent() + 1
      }
    } else {
      const parentNode = node.getParent()
      if (parentNode) {
        key = parentNode.__key
        offset = node.getIndexWithinParent() + 1
      }
    }
  }

  point.set(key, offset, type)
}

function $moveSelectionPointToEndElement(point: PointType, node: LexicalNode): void {
  if ($isElementNode(node)) {
    const lastNode = node.getLastDescendant()
    if ($isElementNode(lastNode) || $isTextNode(lastNode)) {
      selectPointOnNodeElement(point, lastNode)
    } else {
      selectPointOnNodeElement(point, node)
    }
  } else {
    selectPointOnNodeElement(point, node)
  }
}
