import './styles/saveIndicator.css'
import { useEffect, useState } from 'react'

export interface SaveIndicatorPropTypes {
  show?: boolean
  OnResetClick?: () => void
  OnSaveClick?: () => Promise<void>
}

export function SaveIndicator({ show, OnResetClick, OnSaveClick }: SaveIndicatorPropTypes) {
  const [error, setError] = useState<string>()
  let element = document.getElementById('Profile-Options-Save-Indicator-Inner')

  const updateClass = () => {
    element!.classList.remove('Shake-Warning-Indicator')
  }

  useEffect(() => {
    if (element) element.addEventListener('animationend', updateClass)

    return () => element?.removeEventListener('animationend', updateClass)
  }, [element])

  const handleOnClickSave = () => {
    OnSaveClick?.()
      .then(() => setError(undefined))
      .catch((error: Error) => {
        if (error.message) {
          if (error.message.includes('duplicate')) {
            if (error.message.includes('User_pkey')) {
              setError('Notice - Handle is already taken!')
            }
            //TODO add email error
          } else if (error.message.includes('Notice')) {
            setError(error.message)
          }
        } else {
          setError('Notice - Failed to update profile. Please try again later.')
        }
      })
  }

  const handleOnResetClick = () => {
    OnResetClick?.()
    setError(undefined)
  }

  return (
    <>
      <div className={`Profile-Options-Save-Indicator ${show ? 'Profile-Options-Save-Indicator-Slide-In' : ''}`}>
        <div
          className={`Profile-Options-Save-Indicator-Inner ${error && 'Error'}`}
          id="Profile-Options-Save-Indicator-Inner">
          {error ? <p>{error}</p> : <p>Notice - You appear to have unsaved changes!</p>}
          <div className="Profile-Options-Save-Indicator-Buttons">
            <button className="Profile-Options-Save-Indicator-Button-1" onClick={handleOnResetClick}>
              Reset
            </button>
            <button className="Profile-Options-Save-Indicator-Button-2" onClick={handleOnClickSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
