import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'

export function Trending() {
  return (
    <>
      <div className="Sidebar-Trending-Container">
        <NavLink to="#" className="Sidebar-Trending-Item">
          <div className="Sidebar-Trending-Item-Info">
            <h2>Major Update: Vrising</h2>
            <h3>Hotfix: Patch 0.5.41448</h3>
            <p>
              A larger patch is going live today at 11:00 UTC / 13:00 CEST that allows players to play the game without
              an internet connection, new Steam Cloud features, and more. There could be up to 30 minutes of downtime on
              official servers and on player-run servers that are set to auto-update.
            </p>
          </div>
          <div className="Sidebar-Trending-Item-Picture">
            <img
              src="https://cdn.akamai.steamstatic.com/steamcommunity/public/images/clans/40467553/12498bc6c632bce198b3f1ca78befb3762a9f6df_400x225.png"
              alt=""
            />
          </div>
        </NavLink>
        <NavLink to="#" className="Sidebar-Trending-Item">
          <div className="Sidebar-Trending-Item-Info">
            <h2>Major Update: Dota 2</h2>
            <h3>The Battle Report Update</h3>
            <p>
              Today's update commandeers the seasonal Dota Plus refresh to introduce the Battle Report, Weekend
              Spotlight, Unlocked Immortal Spell Effects, and more including the 7.31d Gameplay Update.
            </p>
          </div>
          <div className="Sidebar-Trending-Item-Picture">
            <img
              src="https://cdn.akamai.steamstatic.com/steamcommunity/public/images/clans/3703047/285ff7dc9367d2a0a7676930fde9db3a6c348fea.jpg"
              alt=""
            />
          </div>
        </NavLink>
        <NavLink to="#" className="Sidebar-Trending-Item">
          <div className="Sidebar-Trending-Item-Info">
            <h2>DLC Release: Apex Legends</h2>
            <h3>New Saviors Pack Available Now!</h3>
            <p>
              Leap into the fray with the Saviors Pack! Inside you'll find Newcastle's Rare "Golden Guard" skin and 600
              Apex Coins to style your Legends! Get yours today!
            </p>
          </div>
          <div className="Sidebar-Trending-Item-Picture">
            <img src="https://cdn.akamai.steamstatic.com/steam/apps/1948850/header.jpg?t=1652980062" alt="" />
          </div>
        </NavLink>
      </div>
    </>
  )
}
