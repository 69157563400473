import React, { useEffect, useReducer } from 'react'
import { AuthenticationFlowState } from '../components/authentication'
import { User as FirebaseUser, getAuth, onAuthStateChanged } from 'firebase/auth'

export type SignUpRequest = {
  email: string
  password: string
  username: string
}

export enum AuthenticationState {
  Authenticated = 'authenticated',
  Anonymous = 'anonymous',
  Unconfirmed = 'unconfirmed',
  Loading = 'loading',
}

const AuthState: {
  authUser: FirebaseUser | undefined
  flowState: AuthenticationFlowState
  authenticationState: AuthenticationState
} = {
  authUser: undefined,
  flowState: AuthenticationFlowState.None,
  authenticationState: AuthenticationState.Loading,
}

export enum AuthContextActionType {
  SetAuthUser = 'setAuthUser',
  SetFlowState = 'setFlowState',
  SetAuthenticationState = 'setAuthenticationState',
}

type Action =
  | { type: AuthContextActionType.SetAuthUser; payload: typeof AuthState.authUser }
  | { type: AuthContextActionType.SetFlowState; payload: typeof AuthState.flowState }
  | { type: AuthContextActionType.SetAuthenticationState; payload: typeof AuthState.authenticationState }

export const AuthStateContext = React.createContext(AuthState)
export const AuthDispatchContext = React.createContext((_payload: Action) => {})

export const AuthReducer = (state: typeof AuthState, action: Action) => {
  switch (action.type) {
    case AuthContextActionType.SetAuthUser:
      return {
        ...state,
        authUser: action.payload,
      }
    case AuthContextActionType.SetFlowState:
      return {
        ...state,
        flowState: action.payload,
      }
    case AuthContextActionType.SetAuthenticationState:
      return {
        ...state,
        authenticationState: action.payload,
      }
    default:
      console.error(`Notice: Unhandled action: ${action}`)
      return state
  }
}

type AuthProps = {
  children: JSX.Element
}

export const AuthProvider = ({ children }: AuthProps) => {
  const [state, dispatch] = useReducer(AuthReducer, AuthState)
  const auth = getAuth()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        dispatch({ type: AuthContextActionType.SetAuthUser, payload: user })
        dispatch({
          type: AuthContextActionType.SetAuthenticationState,
          payload: user.emailVerified ? AuthenticationState.Authenticated : AuthenticationState.Unconfirmed,
        })
        !user.emailVerified &&
          dispatch({ type: AuthContextActionType.SetFlowState, payload: AuthenticationFlowState.ConfirmingSignUp })
      } else {
        dispatch({ type: AuthContextActionType.SetAuthUser, payload: undefined })
        dispatch({ type: AuthContextActionType.SetAuthenticationState, payload: AuthenticationState.Anonymous })
        dispatch({ type: AuthContextActionType.SetFlowState, payload: AuthenticationFlowState.None })
      }
    })

    return () => unsubscribe()
  }, [auth])

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}
